import { styled as styledMUI, useTheme } from "@mui/material/styles";
import { interfaceButtonEffectShadow } from "./interfaceButtonEffectShadow";
import { Button } from "@mui/material";
import { ArrowBack, ArrowBackRounded } from "@mui/icons-material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import Colors from "../../../../config/Colors";
function ButtonEffectShadow(paramsComponent: interfaceButtonEffectShadow) {
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const ButtonMain = styledMUI("button")(({ theme }) => ({
        color: paramsComponent.colors.text,
        background: paramsComponent.colors.background,
        pointerEvents: "auto",
        paddingInline: paramsComponent.styleMainDiv?.paddingInline ?? "2rem",
        lineHeight: paramsComponent.styleMainDiv?.lineHeight,
        textAlign: "center",
        border: "2px solid",
        borderColor: `${paramsComponent.colors.background}`,
        overflow: "hidden",
        position: "relative",
        zIndex: "2",
        boxSizing: "border-box",
        paddingBlock: "10px",
        fontSize: paramsComponent.styleMainDiv?.fontSize,
        boxShadow: "unset",
        '&:hover': {
            background: paramsComponent.colors.hoverBackground,
            boxShadow: Colors.shadow.shadowButtonBoxHovered,
        }
    }));


    return (
        <ButtonMain
            // size={paramsComponent.size ?? "large"}
            onClick={paramsComponent.onclick}
            // endIcon={paramsComponent.iconEnded}
            style={{

                whiteSpace: "nowrap",
                fontFamily: confComps.font.poppins.Medium,
                fontSize: "1.28rem",
                lineHeight: "1.92rem",
                ...paramsComponent.styleMainDiv,

            }}
        // style={{fontSize:"1px !important"}}
        >

            {paramsComponent.element ?? paramsComponent.text}
        </ButtonMain >
    )
}
export default ButtonEffectShadow;