import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const LockIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 1.0625C8.65625 1.0625 10 2.49023 10 4.25V6.375H4V4.25C4 2.49023 5.34375 1.0625 7 1.0625ZM3 4.25V6.375H2.5C1.11875 6.375 0 7.56367 0 9.03125V14.3438C0 15.8113 1.11875 17 2.5 17H11.5C12.8813 17 14 15.8113 14 14.3438V9.03125C14 7.56367 12.8813 6.375 11.5 6.375H11V4.25C11 1.90254 9.20937 0 7 0C4.79063 0 3 1.90254 3 4.25ZM2.5 7.4375H11.5C12.3281 7.4375 13 8.15137 13 9.03125V14.3438C13 15.2236 12.3281 15.9375 11.5 15.9375H2.5C1.67188 15.9375 1 15.2236 1 14.3438V9.03125C1 8.15137 1.67188 7.4375 2.5 7.4375ZM7.5 10.3594C7.5 10.0672 7.275 9.82812 7 9.82812C6.725 9.82812 6.5 10.0672 6.5 10.3594V13.0156C6.5 13.3078 6.725 13.5469 7 13.5469C7.275 13.5469 7.5 13.3078 7.5 13.0156V10.3594Z"
                fill={style?.color ?? "#59618C"} />
        </svg>
    )
}
