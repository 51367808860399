import { InterfaceIconSVG } from "../InterfaceIconSVG"

export const HamburgerMenu: React.FC<InterfaceIconSVG> = ({ style }) => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6666 19.3333C24.1802 19.3336 24.674 19.5314 25.0458 19.8859C25.4175 20.2403 25.6386 20.7242 25.6633 21.2372C25.688 21.7502 25.5143 22.2531 25.1784 22.6416C24.8424 23.0301 24.3698 23.2744 23.8586 23.324L23.6666 23.3333H2.33325C1.81963 23.3331 1.32579 23.1352 0.95407 22.7808C0.582347 22.4263 0.361236 21.9425 0.336553 21.4294C0.31187 20.9164 0.485508 20.4136 0.821486 20.0251C1.15746 19.6366 1.63003 19.3922 2.14125 19.3427L2.33325 19.3333H23.6666ZM23.6666 10C24.197 10 24.7057 10.2107 25.0808 10.5858C25.4559 10.9609 25.6666 11.4696 25.6666 12C25.6666 12.5304 25.4559 13.0391 25.0808 13.4142C24.7057 13.7893 24.197 14 23.6666 14H2.33325C1.80282 14 1.29411 13.7893 0.919038 13.4142C0.543966 13.0391 0.333252 12.5304 0.333252 12C0.333252 11.4696 0.543966 10.9609 0.919038 10.5858C1.29411 10.2107 1.80282 10 2.33325 10H23.6666ZM23.6666 0.666666C24.197 0.666666 24.7057 0.87738 25.0808 1.25245C25.4559 1.62753 25.6666 2.13623 25.6666 2.66667C25.6666 3.1971 25.4559 3.70581 25.0808 4.08088C24.7057 4.45595 24.197 4.66667 23.6666 4.66667H2.33325C1.80282 4.66667 1.29411 4.45595 0.919038 4.08088C0.543966 3.70581 0.333252 3.1971 0.333252 2.66667C0.333252 2.13623 0.543966 1.62753 0.919038 1.25245C1.29411 0.87738 1.80282 0.666666 2.33325 0.666666H23.6666Z"
        fill={style?.background ? `${style?.background}` : undefined} />
    </svg>
  )
}
