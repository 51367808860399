import { profile } from "console";
import { EnumSectionsSidebar } from "./enums/EnumSectionsSidebar";

const Links = {
    screens: {
        loginAndRegister: {
            login: "/login/",
        },
        panel: {
            event: {
                addEvent: `/panel/${EnumSectionsSidebar.event}/add/`,
            },
            business: {
                addBusiness: `/panel/${EnumSectionsSidebar.business}/add/`,
            },
            guide: {
                addGuide: `/panel/${EnumSectionsSidebar.guide}/add/`,
            },
        }

    },
}
export default Links;