import { useEffect, useRef, useState } from "react";
import { keyframes, styled, useTheme } from "@mui/material";
import { SyncRounded } from "@mui/icons-material";
import Colors from "../../../config/Colors"; // Adjust this path based on your project structure

import FlagImage from "../../basic/FlagImage/FlagImage"; // You can remove this if unnecessary
import { TimeZoneData } from "../../../data/TimeZoneData";
import { InterfaceSelectTimeZone } from "./InterfaceSelectTimeZone";
import { InterfaceNTTTimeZone } from "../../../NTTs/InterfaceNTTTimeZone";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));

const RowOfList = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    fontSize: "1rem",
    width: "100%",
    gap: "1rem",
    paddingInline: "1rem",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,
    }
}));

export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#F8F8F8",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});

function SelectTimeZone(paramsComponent: InterfaceSelectTimeZone) {
    const mTheme = useTheme();
    const [getSelectedTimeZone, setSelectedTimeZone] = useState<InterfaceNTTTimeZone>();
    const [getSearchText, setSearchText] = useState<string>(paramsComponent.defaultSelected?.text ?? "");
    const [getIsFocused, setIsFocused] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    useEffect(() => {
        if (getIsFocused) {
            const handleClickOutside = (event: MouseEvent) => {
                if (containerRef.current && getIsFocused && !containerRef.current.contains(event.target as Node)) {
                    setIsFocused(false);
                    setSearchText(getSelectedTimeZone?.text ?? "");
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [getIsFocused]);
    useEffect(() => {
        paramsComponent.onChangeSelectedState(getSelectedTimeZone)
    }, [getSelectedTimeZone])
    useEffect(() => {
        setSelectedTimeZone(paramsComponent.defaultSelected);
        setSearchText(paramsComponent.defaultSelected?.text ?? "");
    }, [paramsComponent.defaultSelected])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', position: "relative" }}
            ref={containerRef}
        >
            <label style={{
                fontWeight: "300",
                fontSize: '1.12rem',
                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light, marginBottom: '0.22rem'
            }}>
                Time Zone
            </label>
            <div style={{
                background: Colors.theme.white.white90,
                border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                borderRadius: '0.4rem',
                width: "100%",
                display: "flex", flexDirection: "row",
                alignItems: 'center',
                paddingInline: '0.64rem',
                boxSizing: "border-box",
            }}>
                <input
                    autoComplete="off"
                    type="text"
                    onFocus={() => setIsFocused(true)}
                    style={{
                        flexGrow: "1",
                        paddingInline: '0.64rem',
                        paddingBlock: '0.32rem',
                        cursor: "pointer",
                        fontSize: '1.12rem',
                        color: Colors.theme.blue.dark,
                        outline: 'none',
                    }}
                    value={getSearchText}
                    onChange={handleChangeInput}
                />
            </div>
            {
                <DropdownBox
                    isDropdownOpen={getIsFocused}
                >
                    {TimeZoneData
                        .filter(zone => zone.text.toLowerCase().includes(getSearchText.toLowerCase()))
                        .map((zone, index) => (
                            <RowOfList key={index} onClick={() => {
                                setSearchText(zone.text);
                                setSelectedTimeZone(zone);
                                setIsFocused(false);
                            }}>
                                {zone.text}
                            </RowOfList>
                        ))
                    }
                </DropdownBox>
            }
        </div>
    );
}

export default SelectTimeZone;