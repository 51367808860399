import { InterfaceIconSVG } from "../InterfaceIconSVG"

export const NoneEyeIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width={style?.width} height={style?.height} viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 0.635L1.14 0L9.5 8.36L8.865 9L7.325 7.46C6.75 7.65 6.14 7.75 5.5 7.75C3 7.75 0.865 6.195 0 4C0.345 3.12 0.895 2.345 1.595 1.73L0.5 0.635ZM5.5 2.5C5.89782 2.5 6.27936 2.65804 6.56066 2.93934C6.84196 3.22064 7 3.60218 7 4C7.00025 4.17028 6.97151 4.33937 6.915 4.5L5 2.585C5.16063 2.52849 5.32972 2.49975 5.5 2.5ZM5.5 0.25C8 0.25 10.135 1.805 11 4C10.5917 5.03647 9.89828 5.93616 9 6.595L8.29 5.88C8.98147 5.40172 9.53914 4.75455 9.91 4C9.50583 3.17493 8.87827 2.47982 8.09868 1.99368C7.31909 1.50754 6.41874 1.24988 5.5 1.25C4.955 1.25 4.42 1.34 3.92 1.5L3.15 0.735C3.87 0.425 4.665 0.25 5.5 0.25ZM1.09 4C1.49417 4.82507 2.12173 5.52018 2.90132 6.00632C3.68091 6.49246 4.58126 6.75012 5.5 6.75C5.845 6.75 6.185 6.715 6.5 6.645L5.36 5.5C5.01208 5.46271 4.68741 5.30744 4.43999 5.06001C4.19256 4.81259 4.03729 4.48792 4 4.14L2.3 2.435C1.805 2.86 1.39 3.39 1.09 4Z"
                fill={style?.background ? `${style?.background}` : "#2D3250"} />
        </svg>
    )
};

