import { styled, useTheme } from "@mui/material/styles";
import Colors from "../../../config/Colors";

export const BackSide = styled("div", {
  shouldForwardProp: (prop) => prop !== 'isSubSidebarOpen'
    && prop !== 'isWidthSmall'
})<{ isSubSidebarOpen: boolean; isWidthSmall: boolean }>(({
  theme,
  isSubSidebarOpen,
  isWidthSmall,
}) => {
  return (
    {
      height: "100vh",
      background: Colors.theme.white.PrimaryLight,
      display: 'flex',
      flexDirection: 'row',
      alignItems: "start",
      boxSizing: "border-box",
      justifyContent: "space-between",
      position: "relative",
      flexGrow: 0,
      flexBasis: (isSubSidebarOpen && !isWidthSmall) ? "270px" : "88px",
      transition: isWidthSmall ? undefined : "all 1s ease",
      width: 0,//(isSubSidebarOpen && !isWidthSmall) ? "270px" : "88px",
      zIndex: 3,
    })
})

export const ItemMenu = styled("div", { shouldForwardProp: (prop) => prop !== 'isSelected' })<{ isSelected: boolean; }>(({
  theme,
  isSelected
}) => {
  return (
    {
      userSelect:"none",
      cursor: "pointer",
      paddingBlock: "15px",
      boxSizing: "border-box",
      width: "100%",
      background: isSelected ? Colors.theme.white.SecondaryLight : undefined,
      display: 'flex',
      flexDirection: 'column',
      alignItems: "center",
      justifyContent: "center",
    }
  )
});
export const ItemMenuText = styled("p", { shouldForwardProp: (prop) => prop !== 'isSelected' })<{ isSelected: boolean; }>(({
  theme,
  isSelected
}) => {
  return (
    {
      width: "100%",
      userSelect:"none",
      color: isSelected ? Colors.theme.blue.dark : Colors.theme.blue.blue,
      display: 'flex',
      flexDirection: 'column',
      alignItems: "center",
      justifyContent: "center",
    }
  )
});