import { LinearProgress, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { styled as styledMUI } from "@mui/material/styles";
import { useDispatch } from "react-redux";

import { enumTypeOfProgressBarInRoot, interfaceProgressBarInRoot } from "./interfaceProgressBarInRoot";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import Colors from "../../../config/Colors";

const SnackbarCustom = styledMUI(Snackbar)(({ theme }) => ({
    left: "auto !important",
    right: "auto !important",
    top: "1px !important",
    bottom: "auto !important",
    width: "100%",
    height: "0.4rem",
    position: "absolute",

}));
const ProgressBarCustom = styledMUI(LinearProgress)(({ theme }) => ({
    height: "100%",
    "& span.MuiLinearProgress-bar1Indeterminate": {
        backgroundColor: `${Colors.theme.blue.blue}`,
    },
    "& span.MuiLinearProgress-bar2Indeterminate": {
        backgroundColor: `${Colors.theme.blue.blue}`,
    },
    backgroundColor: `${Colors.theme.blue.light_2} !important`,

}));
/**
   *
   * if you want progressbar is indetermine you must set vaule <0 and isvisible=true
*/
export const ProgressBarInRoots = (params: interfaceProgressBarInRoot) => {
    const [getIsProgressShow, setIsProgressShow] = useState<boolean>(false);
    const [getIsFirstTime, setIsFirstTime] = useState<boolean>(false);//by this variable i try to ignore first time
    const dispatch = useDispatch();
    useEffect(() => {
        console.log("getIsFirstTime")
        console.log(getIsFirstTime)
        if (getIsFirstTime) {
            setIsProgressShow(true);
        }
        setIsFirstTime(true);
    }, [params.lastTimeMilisUpdate]);
    useEffect(() => {
        dispatch(isVisibilityProgressBar(false));
    }, []);
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            setIsProgressShow(false);
            return;
        }

        setIsProgressShow(false);
    };

    return (
        <SnackbarCustom
            open={params.isVisible || getIsProgressShow}
            autoHideDuration={params.isVisible ? undefined : 1000} onClose={handleClose} style={{ display: params.isVisible ? undefined : "none" }}
        >
            {params.lastTimeMilisUpdate ? <ProgressBarCustom sx={{ width: '100%' }} value={params.isVisible ? params.value : 100} variant={params.isVisible && (params.value < 0) ? undefined : "determinate"} /> : undefined}
        </SnackbarCustom>
    )

}