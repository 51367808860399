export enum enumTypeOfAlert {
    warning="warning",
    error = "error",
    info="info",
    success="success",

}
export interface interfaceAlertPopup {
    typeOfWarning?:enumTypeOfAlert;
    text?:string;
    lastTimeecondsUpdate?:number;
}