import { ArrowSmallRightIcon } from "../../../../assets/icons/svg/ArrowSmallRightIcon";
import Colors from "../../../../config/Colors";
import { InterfaceHeaderSubSidebar } from "./InterfaceHeaderSubSidebar";

function HeaderSubSidebar(componentsParams: InterfaceHeaderSubSidebar) {

    return (
        <div style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            gap: "0.2rem",
            boxSizing: "border-box",
            fontFamily: componentsParams.isSelected ? "Poppins-Medium" : undefined,
            cursor: "pointer",
            paddingBlock: "0.3rem",
            background: (componentsParams.isSelected && !componentsParams.hasChild) ? Colors.theme.blue.dark : undefined,
            color: (componentsParams.isSelected && !componentsParams.hasChild) ? "#F1F2F4" : Colors.theme.blue.dark,
            borderRadius: "12px",
            paddingInlineStart: "8px",
            userSelect: "none",

        }} onClick={() => componentsParams.callbackOnClick()}>

            {componentsParams.hasChild ?
                <ArrowSmallRightIcon style={{
                    background: componentsParams.isSelected ? Colors.theme.blue.blue : Colors.theme.blue.light,
                    transition: "transform 0.3s ease",
                    transform: componentsParams.isSelected ? "rotate(90deg)" : "rotate(0deg)"
                }} /> : undefined}
            {componentsParams.title}

        </div>
    );
}
export default HeaderSubSidebar;