export function isAnySimilarInterfaceResponseErrorFromServer(obj: any): obj is interfaceResponseErrorFromServer {
    return (
        typeof obj.status === "number"
        && isAnySimilarInterfaceResponseDataOfErrorFromServer(obj.data)
    );
}
export interface interfaceResponseErrorFromServer {
    status: number;
    data: interfaceResponseDataOfErrorFromServer;
}

export function isAnySimilarInterfaceResponseDataOfErrorFromServer(obj: any): obj is interfaceResponseDataOfErrorFromServer {
    return (
        typeof obj.success === "boolean"
        && isAnySimilarInterfaceResponseErrorOfDataOfErrorFromServer(obj.errors)
    );
}
export interface interfaceResponseDataOfErrorFromServer {
    success: boolean;
    errors: interfaceResponseErrorOfDataOfErrorFromServer;
}
///////////////////
function isAnySimilarInterfaceResponseErrorOfDataOfErrorFromServer(obj: any): obj is interfaceResponseErrorOfDataOfErrorFromServer {
    try {
        return (
            typeof obj.type === "string"
            && typeof obj.code === "string"
            && typeof obj.detail === "string"
            && isAnySimilarInterfaceResponseListOfErrorOfDataOfErrorFromServer(obj.list[0])
        );
    }
    catch (e: any) {
        return false;
    }
}
export interface interfaceResponseErrorOfDataOfErrorFromServer {

    type: string;
    code: string;
    detail: string;
    list: interfaceResponseListOfErrorOfDataOfErrorFromServer[];
}
/////////////////////
function isAnySimilarInterfaceResponseListOfErrorOfDataOfErrorFromServer(obj: any): obj is interfaceResponseListOfErrorOfDataOfErrorFromServer {
    return (
        typeof obj.type === "string"
        && typeof obj.code === "string"
        && typeof obj.detail === "string"
        && (typeof obj.attr === "string" || obj.attr == null )
    );
}
export interface interfaceResponseListOfErrorOfDataOfErrorFromServer {
    type: string;
    code: string;
    detail: string;
    attr: string | null;
}