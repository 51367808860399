import { Link } from "react-router-dom";
import Links from "../config/Links";
import TextFieldPassword from "../components/basic/TextField/TextFieldPassword/TextFieldPassword";

function Test() {
    return (
        <div style={{
            width: "100%",
            height: "100%",

        }}>
            <TextFieldPassword />
        </div>
    )
}
export default Test;