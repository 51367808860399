import { styled, useTheme } from "@mui/material";
import Colors from "../../../../config/Colors";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { InterfaceButtonColor, InterfaceColorOfButton } from "./InterfaceButtonColor";
import { CSSProperties, MouseEvent } from "react";
// color: InterfaceColorOfButton;
//     styleMainDiv?: CSSProperties | undefined;
const ButtonMain = styled("button", { shouldForwardProp: (prop) => prop !== 'colors' && prop !== 'styleMainDiv' })<{
    colors: InterfaceColorOfButton;
    styleMainDiv: (CSSProperties | undefined);
}>(({
    theme,
    colors,
    styleMainDiv,
}) => {
    return (
        {
            paddingInline: "0.8rem",
            lineHeight: "1.44rem",
            fontSize: "0.96rem",
            cursor: "pointer",
            borderRadius: "500px",
            display: "flex", flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: '0.1rem',
            // styleMainDiv && ...styleMainDiv,
            color: colors.color,
            background: colors.background,
            pointerEvents: "auto",
            paddingBlock: "0.16px",
            textAlign: "center",
            border: "1px solid",
            borderColor: `${colors.color}`,
            overflow: "hidden",
            position: "relative",
            zIndex: "2",
            boxSizing: "border-box",
            boxShadow: "unset",
            '&:hover': {
                background: colors.hover.background,
                boxShadow: Colors.shadow.shadowButtonBoxHovered,
            }
        }
    )
});

function ButtonColor(paramsComponent: InterfaceButtonColor) {
    const mTheme = useTheme();
    var confComps = ConfigComps();


    return (

        <ButtonMain
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
                paramsComponent.onclick(event)

            }}
            style={{
                whiteSpace: "nowrap",
                fontFamily: confComps.font.poppins.Medium,
            }}
            colors={paramsComponent.color}
            styleMainDiv={undefined}
        >
            {paramsComponent.iconStart}
            {paramsComponent.element ?? paramsComponent.text}
            {paramsComponent.iconEnd}
        </ButtonMain >
    );
}
export default ButtonColor;