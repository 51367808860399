import { createSlice } from "@reduxjs/toolkit";
import { Direction } from "@mui/material";

export type InitialProfilesState = {
    token: string;
    refreshToken: string;
    lastUpdatePosixTime: number;
    idUser: number;
};
export interface ProfileStructure {
    profiles: InitialProfilesState
}

export const profileStateSlice = createSlice({
    name: "profiles",
    initialState: { token: "", refreshToken: "", idUser: -1, lastUpdatePosixTime: -1 } as InitialProfilesState,
    reducers: {
        SetTokenAndRefreshToken: (state, action: { payload: InitialProfilesState }) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            // state.direction = state.direction == "rtl" ? "ltr" : "rtl";
            // console.log("SetProfiles");
            // console.log(state);
            // console.log(action);
            // state.lastUpdatePosixTime = action.payload.lastUpdatePosixTime;
            // state.lastUpdatePosixTime = Date.now();
            // console.log("action.payload");    
            state.lastUpdatePosixTime = action.payload.lastUpdatePosixTime;
            state.refreshToken = action.payload.refreshToken;
            state.token = action.payload.token;
            state.idUser = action.payload.idUser;

        },
    },
});

// Action creators are generated for each case reducer function
export const { SetTokenAndRefreshToken } = profileStateSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const SelectedProfile = (state: ProfileStructure) => {
    // console.log("selectProfile called");
    // console.log(state);
    return state.profiles;
};

export default profileStateSlice.reducer;