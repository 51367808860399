import { useNavigate } from "react-router-dom";
import { InterfaceItemsSubSidebar } from "./InterfaceItemsSubSidebar";
import Colors from "../../../../config/Colors";

function ItemsSubSidebar(componentsParams: InterfaceItemsSubSidebar) {
    const navigate = useNavigate();
    return (
        <div style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            gap: "0.5rem",
            fontFamily: componentsParams.isSelected ? "Poppins-Medium" : undefined,
            cursor: "pointer",
            userSelect: "none",
            paddingBlock: componentsParams.isShow ? "0.3rem" : "0",
            background: componentsParams.isSelected ? Colors.theme.blue.dark : undefined,
            color: componentsParams.isSelected ? "#F1F2F4" : Colors.theme.blue.dark,
            borderRadius: "12px",
            paddingInlineStart: "28px",
            boxSizing: "border-box",
            zIndex: componentsParams.zIndex,
            overflow: componentsParams.isShow ? "visible" : "hidden",
            marginTop: componentsParams.isShow ? "0" : "-8px",
            maxHeight: componentsParams.isShow ? "100px" : "0px",
            opacity: componentsParams.isShow ? 1 : 0,
            transition: 'max-height 0.3s ease , margin-top 0.3s ease , padding-block 0.3s ease , opacity 500ms ease-in'

        }} onClick={() => {
            navigate(componentsParams.link)
        }}>
            {/* <p
                style={{
                    flexGrow: 1,
                    opacity: componentsParams.isShow ? 1 : 0,
                    transition: "opacity 500ms ease-in",

                }}> */}
            {componentsParams.title}
            {/* </p> */}
        </div>
    );

}
export default ItemsSubSidebar;