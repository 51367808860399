export enum enumListSizeDevices {
    small_mobile = 0,
    mobile = 1,
    tablet = 2,
    desktop = 3,
    tv = 4,
};
export const configResponsive={
    display_string_px: {
        small_mobile: "300px",
        mobile: "600px",
        tablet: "900px",
        desktop: "1200px",
        tv: "1536px",
    },
    display_int: {
        small_mobile: 300,
        mobile: 600,
        tablet: 900,
        desktop: 1200,
        tv: 1536,
    },

}
export default configResponsive;