import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const EnvelopeIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 1.1875C1.44687 1.1875 1 1.6623 1 2.25V3.5748L7.1125 8.33613C7.64063 8.74785 8.35937 8.74785 8.8875 8.33613L15 3.5748V2.25C15 1.6623 14.5531 1.1875 14 1.1875H2ZM1 4.89297V10.75C1 11.3377 1.44687 11.8125 2 11.8125H14C14.5531 11.8125 15 11.3377 15 10.75V4.89297L9.47812 9.19277C8.59687 9.87676 7.4 9.87676 6.52187 9.19277L1 4.89297ZM0 2.25C0 1.07793 0.896875 0.125 2 0.125H14C15.1031 0.125 16 1.07793 16 2.25V10.75C16 11.9221 15.1031 12.875 14 12.875H2C0.896875 12.875 0 11.9221 0 10.75V2.25Z"
                fill={style?.color ?? "#2D3250"} />
        </svg>
    )
}
