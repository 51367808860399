import { CSSProperties } from "react";

export enum EnumStateFetchList {
    searching = "searching",
    initiate = "initiate",
    foundData = "foundData",
    NotFounded = "NotFounded",
    errorInRequest = "errorInRequest",
}
export interface InterfaceShowStateFetchList {
    state: EnumStateFetchList;
    styleParent?: CSSProperties;
    callbackRefreshList: () => void;
}