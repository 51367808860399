import React from 'react';
import { InterfaceSkeletonLoader } from './InterfaceSkeletonLoader';

const SkeletonLoader: React.FC = (paramsComponent: InterfaceSkeletonLoader) => {
    return (
        // <div style={{ display: 'flex', gap: '10px' }}>
        <div style={{
            width: '100%',
            height: '100%',
            minHeight: "100px",
            boxSizing: "border-box",
            backgroundColor: '#e0e0e0',
            borderRadius: '8px',
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            padding: '16px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            overflow: 'hidden',
            gap: "0.5rem",
            opacity:"0.9",
        }}>
            <div style={{
                width: '100%',
                boxSizing: "border-box",
                borderRadius: '8px',
                overflow: 'hidden',
                flexGrow: 1,
                position: 'relative',
                background: '#ecebeb',
                
            }}>
                <svg style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    boxSizing: "border-box",
                }}>
                    <defs>
                        <linearGradient id="animated-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#f3f3f3" stopOpacity="1">
                                <animate attributeName="offset" values="0; 1; 1" keyTimes="0; 0.5; 1" dur="2s" repeatCount="indefinite" />
                            </stop>
                            <stop offset="50%" stopColor="#ececec" stopOpacity="0.5" />
                            <stop offset="100%" stopColor="#f5f5f5" stopOpacity="0.5" />
                        </linearGradient>
                    </defs>
                    <rect x="0" y="0" width="100%" height="100%" fill="url(#animated-gradient)" />
                </svg>
            </div>
            {/* <div style={{
                height: '20px',
                borderRadius: '4px',
                marginBottom: '10px',
                background: '#ecebeb',
                position: 'relative',
                overflow: 'hidden'
            }}>
                <svg style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}>
                    <rect x="0" y="0" width="100%" height="100%" fill="url(#animated-gradient)" />
                </svg>
            </div> */}
            <div style={{
                flexGrow: 0.3,
                width: '80%',
                borderRadius: '8px',
                background: '#ecebeb',
                position: 'relative',
                overflow: 'hidden'
            }}>
                <svg style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}>
                    <rect x="0" y="0" width="100%" height="100%" fill="url(#animated-gradient)" />
                </svg>
            </div>

            <div style={{
                flexGrow: 0.5,
                width: '60%',
                borderRadius: '8px',
                background: '#ecebeb',
                position: 'relative',
                overflow: 'hidden'
            }}>
                <svg style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}>
                    <rect x="0" y="0" width="100%" height="100%" fill="url(#animated-gradient)" />
                </svg>
            </div>
        </div>
        // </div>
    );
};

export default SkeletonLoader;
