import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Route } from "react-router-dom";
import { SelectedProfile } from "../store/profileStateSlice";
import Links from "../config/Links";
// import links from "../config/links";
const PrivateRedirectToLogin = (props: any) => {
  const token = localStorage.getItem("auth");
  const ProfileInStore = useSelector(SelectedProfile);
  // const ws = useRef<WebSocket | null>(null);
  // const dispatch = useDispatch();

  if ((ProfileInStore.lastUpdatePosixTime < 0 || (ProfileInStore.token == undefined))) {
    return (<Navigate to={Links.screens.loginAndRegister.login} />)
  }


  return <Outlet />;


};

export default PrivateRedirectToLogin;