import { CompressRounded, DownloadRounded, FileOpenRounded, UploadFileRounded } from "@mui/icons-material";
import Colors, { ColorThemForButton } from "../../../config/Colors";
import { InterfaceFile, InterfaceListFiles } from "./InterfaceListFiles";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { getTypeOfFileFromLink } from "../../../helper/Helper";
import { TrashIcon } from "../../../assets/icons/svg/TrashIcon";
import ButtonColor from "../../basic/Buttons/ButtonColor/ButtonColor";
import { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
import { useDispatch } from "react-redux";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
interface ExtendedImageResponse {
    mainMedia: {
        uri: string;
        name: string;
        type: string;
    };
}


function ListFiles(paramsComponent: InterfaceListFiles) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const [getAttachFiles, setAttachFiles] = useState<InterfaceFile[]>(paramsComponent.Files);
    useEffect(() => {
        setAttachFiles(paramsComponent.Files);
    }, [paramsComponent.Files])
    // Convert bytes to MB
    const getFileSizeInMB = (fileSizeInBytes: number) => {
        return (fileSizeInBytes / (1024 * 1024)).toFixed(2); // Round to 2 decimal places
    };
    const isImageFile = (file: File | null) => {
        return file && file.type.startsWith('image/');
    };
    const resizeAndCompressImage = async (file: File, isFirstCreated: boolean): Promise<ExtendedImageResponse> => {
        try {
            // Resize and compress options
            const options = {
                maxSizeMB: 1, // Max size in MB
                maxWidthOrHeight: 1024, // Resize if the image exceeds this dimension
                useWebWorker: true, // Optional, for performance
                initialQuality: 1, // Start with full quality and reduce as needed
            };

            // Compress the image
            const compressedFile = await imageCompression(file, options);

            // Create a file reader to convert compressed file into a data URL (uri equivalent)
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.readAsDataURL(compressedFile);
                reader.onload = () => {
                    resolve({
                        mainMedia: {
                            uri: reader.result as string, // Base64 or URL of the image
                            name: compressedFile.name,
                            type: compressedFile.type,
                        },
                    });
                };
                reader.onerror = reject;
            });
        } catch (error) {
            console.error('Error resizing or compressing image:', error);
            throw error;
        }
    };
    // Function to modify a specific object at the given index
    const modifyFile = (index: number, newValue: File) => {
        return getAttachFiles.map((item, i) =>
            i === index ? { ...item, ...{ file: newValue } } : item // Update only the object at the specific index
        )


    };

    const handleCompressClick = async (selectedFile: File, index: number) => {
        dispatch(isVisibilityProgressBar(true));
        if (selectedFile) {
            try {
                const compressedResponse = await resizeAndCompressImage(selectedFile, true);
                console.log('Compressed Image:', compressedResponse);
                // Update the selected file with the compressed file
                const blob = await fetch(compressedResponse.mainMedia.uri).then(res => res.blob());
                const newFile = new File([blob], compressedResponse.mainMedia.name, { type: compressedResponse.mainMedia.type });
                paramsComponent.callBackUpdatedAllFiles(modifyFile(index, newFile)); // Replace the original file with the compressed one
            } catch (error) {
                console.error('Failed to compress image:', error);
            }
        }
        dispatch(isVisibilityProgressBar(false));
    };
    ///function for resize image
    // const resizeAndCompressImage = async (asset: Asset, isFirstCreated: boolean): Promise<ExtendedImageResponse> => {
    //     try {
    //         const { uri, width, height, fileName, type } = asset;

    //         // Check if image needs resizing
    //         let maxDimension = Math.max(width!, height!);
    //         let resizedUri = uri!;
    //         let resizedWidth = width!;
    //         let resizedHeight = height!;

    //         if (maxDimension > 1024) {
    //             const newWidth = width! > height! ? 1024 : (1024 * width!) / height!;
    //             const newHeight = height! > width! ? 1024 : (1024 * height!) / width!;

    //             const response = await ImageResizer.createResizedImage(uri!, newWidth, newHeight, 'JPEG', 100);
    //             resizedUri = response.uri;
    //             resizedWidth = response.width;
    //             resizedHeight = response.height;
    //         }

    //         // Compress the image if necessary to ensure it's under 1MB
    //         let fileSize = await getFileSize(resizedUri);
    //         let quality = 100;

    //         while (fileSize > (1 * 512 * 1024) && quality > 0) {
    //             quality -= 10;
    //             const response = await ImageResizer.createResizedImage(resizedUri, resizedWidth, resizedHeight, 'JPEG', quality);
    //             resizedUri = response.uri;
    //             fileSize = await getFileSize(resizedUri);
    //         }

    //         return {
    //             mainMedia: {
    //                 uri: resizedUri,
    //                 name: fileName!,
    //                 type: type!,
    //             },
    //             isFirstCreated: isFirstCreated,
    //         };
    //     } catch (error) {
    //         console.error('Error resizing or compressing image:', error);
    //         throw error;
    //     }
    // };
    return (
        <div style={{
            display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "start",
            gap: "1rem",
        }}>
            {getAttachFiles.map((file, index, arr) => {
                return (
                    <div style={{
                        display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
                        border: `1px solid ${Colors.theme.blue.border}`,
                        borderRadius: "0.8rem",
                        paddingInline: "1rem",
                        paddingBlock: "1.5rem",
                        width: "100%",
                        boxSizing: "border-box",
                    }}>
                        <div style={{
                            display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "space-between",
                            width: "100%",
                            boxSizing: "border-box",
                        }}>
                            <div style={{
                                display: "flex", flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                gap: "1rem",
                            }}>
                                {<UploadFileRounded style={{ color: Colors.theme.blue.blue }} />}

                                {file.file &&
                                    (<p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        color: Colors.theme.blue.dark,
                                    }}>
                                        {file.file.name}

                                    </p>)
                                }
                                {file.file && (
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        color: Colors.theme.blue.dark,
                                        borderRadius: "500px",
                                        background: Colors.theme.mainColor.primaryLight,
                                        paddingBlock: "4px",
                                        paddingInline: "1rem",

                                    }}>
                                        {`${getFileSizeInMB(file.file.size)} MB`}

                                    </p>
                                )}


                            </div>
                            <div style={{
                                display: 'inline-flex', flexDirection: "row",
                                alignItems: "center", justifyContent: "center",
                                gap: "1rem",
                            }}>
                                {isImageFile(file.file) && (file.file.size > (512 * 1024)) && (
                                    <ButtonColor
                                        onclick={(event) => {
                                            event.stopPropagation();
                                            handleCompressClick(file.file, index);
                                        }}
                                        iconStart={<CompressRounded />}
                                        text="Compress"
                                        color={ColorThemForButton.greenColorAndTransparentBackground}
                                    />


                                )}
                                <div style={{
                                    paddingInline: "0.1rem",
                                    cursor: "pointer", display: "inline-flex"
                                }}
                                    onClick={() => {
                                        paramsComponent.callbackRemoveFiles(index);
                                    }}
                                >
                                    <TrashIcon style={{ color: Colors.theme.orange.sharp }} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

        </div>
    );
}
export default ListFiles;