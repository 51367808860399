import { InterfaceTextFieldPassword } from "./InterfaceTextFieldPassword";

import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { NoneEyeIcon } from "../../../../assets/icons/svg/NoneEyeIcon";
import { EyeIcon } from "../../../../assets/icons/svg/EyeIcon";
import Colors from "../../../../config/Colors";

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    maxWidth: 300,
}));

const StyledInputContainer = styled('div')(({ theme }) => ({
    width: '100%',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingInline: '8px',
    paddingBlock: '4px',
    borderRadius: '5px',
    border: `1px solid rgb(196,199,217)`,
    '&:hover': {
        borderColor: theme.palette.primary.dark,
    },
    '&:focus': {
        borderColor: theme.palette.primary.dark,
    },
}));

const StyledInput = styled('input')(({ theme }) => ({
    flexGrow: "1",

}));

const ToggleButton = styled('button')({
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
});

const RequirementText = styled('p')(({ theme }) => ({
    color: theme.palette.error.main,
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    '&::before': {
        content: '"•"',
        marginRight: theme.spacing(1),
    },
}));
function TextFieldPassword(componentParams: InterfaceTextFieldPassword) {
    const [getPasswordText, setPasswordText] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordText(event.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Container>
            <StyledInputContainer>
                <StyledInput
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Confirm Password"
                    value={getPasswordText}
                    onChange={handlePasswordChange}
                />
                <ToggleButton onClick={handleClickShowPassword}>
                    {showPassword ? <NoneEyeIcon
                        style={{ background: Colors.theme.blue.dark, width: "1.3rem", height: "1.3rem" }} /> : <EyeIcon style={{
                            background: getPasswordText.length > 0 ? Colors.theme.blue.dark : Colors.theme.blue.light,
                            width: "1.3rem", height: "1.3rem"
                        }} />}
                </ToggleButton>
            </StyledInputContainer>
            <RequirementText>Minimum characters 12</RequirementText>
            <RequirementText>One number</RequirementText>
        </Container>
    );

}
export default TextFieldPassword;