import { Typography } from "@mui/material";
import { InterfaceSubTitleSection } from "./InterfaceSubTitleSection";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import Colors from "../../../../../config/Colors";

function SubTitleSection(paramsComponent: InterfaceSubTitleSection) {
    var confComps = ConfigComps();
    return (
        <Typography variant="h5" sx={{
            lineHeight: { xs: 1.1, sm: 1.1, md: 1.25, lg: 1.25, xl: 1.25 },
            fontSize: { xs: "0.96rem", sm: "1.04rem", md: "1.12rem", lg: "1.2rem", xl: "1.28rem" },
            marginBlock: { xs: 2, sm: 3, md: 3, lg: 3, xl: 3 },
            fontFamily: confComps.font.poppins.Medium,
            fontWeight: "200",
            color: Colors.theme.blue.dark,
            ...paramsComponent.style
        }} >
            {paramsComponent.title}
        </Typography>
    );

}
export default SubTitleSection;