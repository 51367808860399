import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const ArrowSmallRightIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{
                transform: style?.transform,
                transition: style?.transition
            }}>
            <path d="M5.02901 3.39883C5.47143 3.79617 5.47143 4.48948 5.02901 4.88682L1.73989 7.84078C1.09613 8.41894 0.0717077 7.96205 0.0717077 7.09678L0.0717077 1.18886C0.0717077 0.32359 1.09613 -0.133295 1.73989 0.444866L5.02901 3.39883Z"
                fill={style?.background ? `${style?.background}` : undefined}
            />
        </svg>
    )
};

