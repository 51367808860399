import { InterfaceIconSVG } from "../InterfaceIconSVG"

export const EyeIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width={style?.width} height={style?.height} viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 2.5C5.89782 2.5 6.27936 2.65804 6.56066 2.93934C6.84196 3.22064 7 3.60218 7 4C7 4.39782 6.84196 4.77936 6.56066 5.06066C6.27936 5.34196 5.89782 5.5 5.5 5.5C5.10218 5.5 4.72064 5.34196 4.43934 5.06066C4.15804 4.77936 4 4.39782 4 4C4 3.60218 4.15804 3.22064 4.43934 2.93934C4.72064 2.65804 5.10218 2.5 5.5 2.5ZM5.5 0.25C8 0.25 10.135 1.805 11 4C10.135 6.195 8 7.75 5.5 7.75C3 7.75 0.865 6.195 0 4C0.865 1.805 3 0.25 5.5 0.25ZM1.09 4C1.49413 4.82515 2.12165 5.52037 2.90124 6.00663C3.68083 6.49288 4.5812 6.75066 5.5 6.75066C6.4188 6.75066 7.31917 6.49288 8.09876 6.00663C8.87835 5.52037 9.50587 4.82515 9.91 4C9.50587 3.17485 8.87835 2.47963 8.09876 1.99337C7.31917 1.50712 6.4188 1.24934 5.5 1.24934C4.5812 1.24934 3.68083 1.50712 2.90124 1.99337C2.12165 2.47963 1.49413 3.17485 1.09 4Z"
                fill={style?.background ? `${style?.background}` : undefined}
            />
        </svg>
    )
};
