import React, { useEffect, useRef } from 'react';
import { InterfaceFlagImage } from './InterfaceFlagImage';


const FlagImage: React.FC<InterfaceFlagImage> = ({ emojiU }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const context = canvas.getContext('2d');
            if (context) {
                const flag = emojiU.split(' ')
                    .map(u => String.fromCodePoint(parseInt(u.replace('U+', ''), 16))).join('');
                context.font = '24px serif';
                context.fillText(flag, 0, 24);
            }
        }
    }, [emojiU]);

    return (
        <canvas ref={canvasRef} width="24" height="24" />
    );
};

export default FlagImage;
