import { title } from "process";
import { EnumSectionsSidebar } from "./enums/EnumSectionsSidebar";
import Links from "./Links";
import { useTranslation } from "react-i18next";

import { AssignmentRounded, Category, EventNoteRounded, StoreRounded } from "@mui/icons-material";
import { InterfacePaginationInServer } from "../api/interfaces/InterfaceSearchInServer";


export const ConfigComps = () => {
    const { t, i18n } = useTranslation();
    return (
        {
            defaultValue: {
                paginationConfigForServer: {
                    page: 1, page_size: 2
                } as InterfacePaginationInServer,
            },
            font: {
                poppins: {
                    Black: "Poppins-Black",
                    BlackItalic: "Poppins-BlackItalic",
                    Bold: "Poppins-Bold",
                    BoldItalic: "Poppins-BoldItalic",
                    ExtraBold: "Poppins-ExtraBold",
                    ExtraBoldItalic: "Poppins-ExtraBoldItalic",
                    ExtraLight: "Poppins-ExtraLight",
                    ExtraLightItalic: "Poppins-ExtraLightItalic",
                    Italic: "Poppins-Italic",
                    Light: "Poppins-Light",
                    LightItalic: "Poppins-LightItalic",
                    Medium: "Poppins-Medium",
                    MediumItalic: "Poppins-MediumItalic",
                    Regular: "Poppins-Regular",
                    SemiBold: "Poppins-SemiBold",
                    SemiBoldItalic: "Poppins-SemiBoldItalic",
                    Thin: "Poppins-Thin",
                    ThinItalic: "Poppins-ThinItalic",

                },
            },
            images: {
                logo: "/assets/img/logo.png",
                logoWithoutText: "/assets/img/logo_without_text.png",
            },
            sidebar: {
                items: [
                    {
                        title: "Guide",
                        icon: AssignmentRounded,
                        key: EnumSectionsSidebar.guide,
                        link: Links.screens.panel.guide.addGuide,
                    },
                    {
                        title: "Event",
                        icon: EventNoteRounded,
                        key: EnumSectionsSidebar.event,
                        link: Links.screens.panel.event.addEvent,
                    },
                    {
                        title: "Business",
                        icon: StoreRounded,
                        key: EnumSectionsSidebar.business,
                        link: Links.screens.panel.business.addBusiness,
                    }
                ],
            },
            component: {
                advanceSearchBox: {
                    hintText: t(`component.advanceSearchBox.hintText`),
                    buttonAdvance: t(`component.advanceSearchBox.buttonAdvance`),
                },
                SortingBox: {
                    title: t(`component.SortingBox.title`),
                },
                ShowStateFetchList: {
                    searching: {
                        title: t(`component.ShowStateFetchList.searching.title`),
                        button: t(`component.ShowStateFetchList.searching.button`),
                    },
                    foundData: {
                    },
                    NotFounded: {
                        title: t(`component.ShowStateFetchList.NotFounded.title`),
                        button: t(`component.ShowStateFetchList.NotFounded.button`),
                    },
                    errorInRequest: {
                        title: t(`component.ShowStateFetchList.errorInRequest.title`),
                        button: t(`component.ShowStateFetchList.errorInRequest.button`),
                    }
                },
            },

            translate: {
                uploadImage: "Upload Image",
                state: "State",
                dragDropFiles: "Drag and drop file",
                region: "Region",
                regions: "Regions",
                originCountry: "Origin Country",
                startDate: "Start Date",
                endDate: "End Date",
                website: "Website",
                search:"Search",

                phone: "Phone",
                location: "Location",
                showSimilar: "Show Similar",
                isPrivate: "Is Private?",
                isOnline: "Is Online?",
                category: "Category",
                submit: "Submit",
                add: "Add",
                addGuide: "Add Guide",
                addEvent: "Add Event",
                addBusiness: "Add Business",
                password: "Password",
                email: "Email",
                exampleEmail: "example@email.com",
                retry: "Retry",
                notFound: "Not Found!",
                description: "Description",
                save: "Save",
                title: "Title",
                cancel: "Cancel",
                country: "Country",
                city: "City",

            },
        }

    );
}
