import { Box, Input, TextareaAutosize } from "@mui/material";
import { InterfaceTextFieldMultiLine } from "./InterfaceTextFieldMultiLine";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import Colors from "../../../../config/Colors";
import { useEffect, useState } from "react";

function TextFieldMultiLine(paramsComponent: InterfaceTextFieldMultiLine) {
    const [getIsFocused, setIsFocused] = useState(false);
    const [getValue, setValue] = useState(paramsComponent.text);
    var confComps = ConfigComps();
    // handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    //     this.setState({ value: event.target.value });
    //   }
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        // setValue(newValue);
        paramsComponent.onChangeText(newValue);
    };
    useEffect(() => {
        setValue(paramsComponent.text);
    }, [paramsComponent.text])
    return (


        <div style={{ display: 'flex', flexDirection: 'column', width: "100%", }}>
            {paramsComponent.title &&
                <label style={{
                    fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                    lineHeight: "1.68rem", color: Colors.theme.blue.light, marginBottom: '0.22rem'
                }}>
                    {paramsComponent.title}
                </label>
            }
            <textarea
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                value={getValue}
                placeholder={paramsComponent.hint}
                onChange={handleChange}
                style={{
                    resize: "none",
                    boxSizing: "border-box",
                    width: "100%",
                    background: Colors.theme.blue.white2,
                    paddingInline: "0.96rem",
                    paddingBlock: "1rem",
                    boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : Colors.shadow.shadowEditTextMultiLineBoxNC,
                    borderRadius: "0.4rem",
                    color: Colors.theme.blue.dark,
                    textDecoration: "none",
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: "0.96rem",
                    outline: "none",
                    border: "none",
                    ...paramsComponent.styleParent,
                }}
            />
        </div>





    )
};
export default TextFieldMultiLine;