// import * as React from 'react';
// import { ConfigComps } from '../../../../config/ConfigCOMP';
// import Colors from '../../../../config/Colors';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { useEffect, useRef, useState } from 'react';
// import dayjs, { Dayjs } from 'dayjs';
// import { InterfaceDatePickerSimple } from './InterfaceDatePickerSimple';


// function DatePickerSimple(componentParams: InterfaceDatePickerSimple) {
//     const confComps = ConfigComps();
//     const [getIsFocused, setIsFocused] = useState(false);
//     const [getIsFocusedTextField, setIsFocusedTextField] = useState(false);
//     const [getIsFocusedOnDialog, setIsFocusedOnDialog] = useState(false);
//     // const [getValue, setValue] = useState<number | undefined>();
//     // useEffect(() => {
//     //     setValue(componentParams.defaultTimeInSecond);
//     // }, [componentParams.defaultTimeInSecond])
//     const [getValue, setValue] = useState<Dayjs | null>(
//         componentParams.defaultTimeInSecond ? dayjs.unix(componentParams.defaultTimeInSecond) : null
//     ); // Modified
//     // const datePickerRef = useRef<any>(null);
//     const containerRef = useRef<HTMLDivElement>(null);

//     useEffect(() => {
//         setValue(componentParams.defaultTimeInSecond ? dayjs.unix(componentParams.defaultTimeInSecond) : null);
//     }, [componentParams.defaultTimeInSecond]);
//     // const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     //     // Use event.rel
//     //     // if (datePickerRef.current && !datePickerRef.current.contains(event.relatedTarget as Node)) {
//     //     setIsFocused(false);
//     //     // }
//     // };
//     useEffect(() => {
//         setIsFocused(getIsFocusedOnDialog || getIsFocusedTextField);
//     }, [getIsFocusedOnDialog, getIsFocusedTextField])
//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <div style={{ display: 'flex', flexDirection: 'column', position: "relative" }}
//                 ref={containerRef}
//             >
//                 <label style={{
//                     fontWeight: "300",

//                     fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
//                     lineHeight: "1.68rem",
//                     color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light, marginBottom: '0.22rem'
//                 }}>
//                     {componentParams.title ?? confComps.translate.startDate}
//                 </label>
//                 <div style={{
//                     background: Colors.theme.white.white90,
//                     border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
//                     boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
//                     borderRadius: '0.4rem',
//                     fontFamily: confComps.font.poppins.Regular,
//                     fontSize: '1.12rem',
//                     width: "100%",
//                     color: Colors.theme.blue.dark,
//                     outline: 'none',
//                     lineHeight: "1.68rem",
//                     display: "flex", flexDirection: "row",
//                     alignItems: 'center',
//                     paddingInline: '0.64rem',
//                     boxSizing: "border-box",
//                 }}>
//                     <DatePicker

//                         onOpen={() => {
//                             setIsFocusedOnDialog(true);
//                             componentParams.onOpenDialogDate && componentParams.onOpenDialogDate();
//                         }}
//                         onClose={() => {
//                             setIsFocusedOnDialog(false);
//                             componentParams.onCloseDialogDate && componentParams.onCloseDialogDate();
//                             setIsFocused(false);
//                         }}
//                         format="YYYY/MM/DD"
//                         onChange={(newValue: Dayjs | null) => {
//                             if (newValue != null) {
//                                 componentParams.onChangeValue(newValue.unix());//return second
//                                 setValue(newValue);
//                             }
//                             else {
//                                 componentParams.onChangeValue(undefined);
//                                 setValue(null);
//                             }
//                         }}
//                         value={getValue}
//                         // defaultValue={getValue ? dayjs.unix(2000020) : undefined}
//                         slotProps={{
//                             textField: {
//                                 onFocus: () => {
//                                     setIsFocusedTextField(true)
//                                 },
//                                 onBlur: () => {
//                                     setIsFocusedTextField(false)
//                                 },
//                                 // placeholder: getIsFocused ? undefined : ""
//                             },

//                             layout: {

//                             }
//                         }}
//                         sx={{
//                             fontSize: '1.12rem',
//                             lineHeight: "1.68rem",
//                             outline: "unset",
//                             width: "100%",
//                             "& div": {
//                                 outline: "unset",
//                                 // paddingInlineEnd: "0rem",
//                                 paddingBlock: '0.22rem',
//                             },
//                             "& input": {
//                                 background: Colors.theme.white.white90,
//                                 outline: "unset",
//                                 boxSizing: "border-box",
//                                 paddingInline: '0.64rem',
//                                 // border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
//                                 // boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
//                                 // borderRadius: '0.4rem',
//                                 fontFamily: confComps.font.poppins.Regular,
//                                 fontSize: '1.12rem',
//                                 lineHeight: "1.68rem",
//                                 color: Colors.theme.blue.dark,
//                                 cursor: "pointer",

//                             },
//                             "& fieldset": {
//                                 outline: "unset",
//                                 border: "unset !important", //getIsFocused ? `1px solid ${Colors.theme.blue.blue} !important` : `1px solid ${Colors.theme.blue.border} !important`,
//                             },
//                             "& button": {
//                                 marginBottom: "0px",
//                                 color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
//                                 // paddingInline: "0px",
//                                 padding: "0rem",
//                             }

//                         }}
//                     />


//                 </div>


//             </div>
//         </LocalizationProvider>
//     );
// }

// export default DatePickerSimple;

import * as React from 'react';
import { ConfigComps } from '../../../../config/ConfigCOMP';
import Colors from '../../../../config/Colors';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'; // Changed import
import { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { InterfaceDatePickerSimple } from './InterfaceDatePickerSimple';

function DatePickerSimple(componentParams: InterfaceDatePickerSimple) {
    const confComps = ConfigComps();
    const [getIsFocused, setIsFocused] = useState(false);
    const [getIsFocusedTextField, setIsFocusedTextField] = useState(false);
    const [getIsFocusedOnDialog, setIsFocusedOnDialog] = useState(false);
    const [getValue, setValue] = useState<Dayjs | null>(
        componentParams.defaultTimeInSecond ? dayjs.unix(componentParams.defaultTimeInSecond) : null
    ); // Modified
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setValue(componentParams.defaultTimeInSecond ? dayjs.unix(componentParams.defaultTimeInSecond) : null);
    }, [componentParams.defaultTimeInSecond]);

    useEffect(() => {
        setIsFocused(getIsFocusedOnDialog || getIsFocusedTextField);
    }, [getIsFocusedOnDialog, getIsFocusedTextField]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', flexDirection: 'column', position: "relative" }}
                ref={containerRef}
            >
                <label style={{
                    fontWeight: "300",
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: '1.12rem',
                    lineHeight: "1.68rem",
                    color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                    marginBottom: '0.22rem'
                }}>
                    {componentParams.title ?? confComps.translate.startDate}
                </label>
                <div style={{
                    background: Colors.theme.white.white90,
                    border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                    boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                    borderRadius: '0.4rem',
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: '1.12rem',
                    width: "100%",
                    color: Colors.theme.blue.dark,
                    outline: 'none',
                    lineHeight: "1.68rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: 'center',
                    paddingInline: '0.64rem',
                    boxSizing: "border-box",
                }}>
                    <DateTimePicker
                        onOpen={() => {
                            setIsFocusedOnDialog(true);
                            componentParams.onOpenDialogDate && componentParams.onOpenDialogDate();
                        }}
                        onClose={() => {
                            setIsFocusedOnDialog(false);
                            componentParams.onCloseDialogDate && componentParams.onCloseDialogDate();
                            setIsFocused(false);
                        }}
                        format="YYYY/MM/DD HH:mm:ss" // Updated format
                        onChange={(newValue: Dayjs | null) => {
                            if (newValue != null) {
                                componentParams.onChangeValue(newValue.unix()); // Return seconds
                                setValue(newValue);
                            } else {
                                componentParams.onChangeValue(undefined);
                                setValue(null);
                            }
                        }}
                        value={getValue}
                        slotProps={{
                            textField: {
                                onFocus: () => {
                                    setIsFocusedTextField(true)
                                },
                                onBlur: () => {
                                    setIsFocusedTextField(false)
                                },
                            },
                        }}
                        sx={{
                            fontSize: '1.12rem',
                            lineHeight: "1.68rem",
                            outline: "unset",
                            width: "100%",
                            "& div": {
                                outline: "unset",
                                paddingBlock: '0.22rem',
                            },
                            "& input": {
                                background: Colors.theme.white.white90,
                                outline: "unset",
                                boxSizing: "border-box",
                                paddingInline: '0.64rem',
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1.12rem',
                                lineHeight: "1.68rem",
                                color: Colors.theme.blue.dark,
                                cursor: "pointer",
                            },
                            "& fieldset": {
                                outline: "unset",
                                border: "unset !important",
                            },
                            "& button": {
                                marginBottom: "0px",
                                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                padding: "0rem",
                            }
                        }}
                    />
                </div>
            </div>
        </LocalizationProvider>
    );
}

export default DatePickerSimple;

