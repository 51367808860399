import { useDispatch, useSelector } from "react-redux";
import ContainerPage from "../../../../components/basic/containerPage/ContainerPage";
import { MainCard } from "../../../../components/basic/MainCard/MainCard";
import { InterfaceCreateBusiness } from "./InterfaceCreateBusiness";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { Box, Grid, Grid2, styled, TextField, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { InterfaceNTTUser } from "../../../../NTTs/InterfaceNTTUser";
import TextFieldSimple from "../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import TextFieldMultiLine from "../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";

import SelectYesOrNo from "../../../../components/complex/SelectYesOrNo/SelectYesOrNo";
import ButtonSave from "../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import ButtonColor from "../../../../components/basic/Buttons/ButtonColor/ButtonColor";
import Colors, { ColorThemForButton } from "../../../../config/Colors";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl, TypeQueryParams } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import ParentWithHeightAnimation from "../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import { InterfaceNTTBusiness } from "../../../../NTTs/InterfaceNTTBusiness";
import { EnumStateFetchList } from "../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import InfiniteScrollList from "../../../../components/basic/InfiniteScrollList/InfiniteScrollList";
import { InterfaceNTTCategoryBusiness, InterfaceNTTSubCategoryBusiness } from "../../../../NTTs/InterfaceNTTCategoryBusiness";
import SelectCategoryBusiness from "../../../../components/complex/SelectCategoryBusiness/SelectCategoryBusiness";
import { InterfaceNTTOriginCountry } from "../../../../NTTs/InterfaceNTTOriginCountry";
import SelectOriginCountry from "../../../../components/complex/SelectOriginCountry/SelectOriginCountry";
import AutoCompleteLocation from "../../../../components/complex/AutoCompleteLocation/AutoCompleteLocation";
import { InterfaceSuggestionLocation } from "../../../../components/complex/AutoCompleteLocation/InterfaceAutoCompleteLocation";
import { OriginCountryData } from "../../../../data/OriginCountryData";
import FlagImage from "../../../../components/basic/FlagImage/FlagImage";
import { InterfaceFile } from "../../../../components/complex/ListFiles/InterfaceListFiles";
import ListFiles from "../../../../components/complex/ListFiles/ListFiles";
import FilesDragAndDrop from "../../../../components/complex/FileDragAndDrop/FileDragAndDrop";
import { AttachFileRounded } from "@mui/icons-material";
import SubTitleSection from "../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import SelectCity from "../../../../components/complex/SelectCity/SelectCity";
import { InterfaceNTTCity } from "../../../../NTTs/InterfaceNTTCity";
import SelectRegion from "../../../../components/complex/SelectRegion/SelectRegion";
import { InterfaceNTTRegion } from "../../../../NTTs/InterfaceNTTRegion";
import MultiSelectorRegion from "../../../../components/complex/MultiSelectorRegion/MultiSelectorRegion";

function CreateBusiness(paramsComponent: InterfaceCreateBusiness) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getTitle, setTitle] = useState<string>();
    // const [getLocation, setLocation] = useState<string>();
    const [getLocation, setLocation] = useState<InterfaceSuggestionLocation>();
    const [getPhone, setPhone] = useState<string>();
    const [getEmail, setEmail] = useState<string>();
    const [getOriginCountry, setOriginCountry] = useState<InterfaceNTTOriginCountry>();
    const [getCountry, setCountry] = useState<InterfaceNTTOriginCountry>();
    const [getRegions, setRegions] = useState<InterfaceNTTRegion[]>([]);
    const [getCategory, setCategory] = useState<InterfaceNTTSubCategoryBusiness>();
    const [getDetails, setDetails] = useState<string>();
    const [getIsPrivate, setIsPrivate] = useState<boolean>(false);
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getResponseAllCategory, setResponseAllCategory] = useState<InterfaceNTTCategoryBusiness[]>([]);
    const [getResponseAllSimilarBusiness, setResponseAllSimilarBusiness] = useState<InterfaceNTTBusiness[]>([]);
    const [getAttachFile, setAttachFile] = useState<InterfaceFile[]>([]);
    const [getWebsite, setWebsite] = useState<string>();
    const clearForm = () => {
        setTitle(undefined);

        setPhone(undefined);
        setEmail(undefined);
        setDetails(undefined);
        setIsPrivate(false);
        setStateFetchList(EnumStateFetchList.initiate);
        setResponseAllSimilarBusiness([]);
        setAttachFile([]);
        setWebsite(undefined);
        setCountry(undefined);
        setOriginCountry(undefined);
        setRegions([]);
        setCategory(undefined);

    }
    const callApiForCreateBusiness = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.business.createBusiness;
        let objectReq = new FormData();
        objectReq.append("title", getTitle ?? "");
        objectReq.append("details", getDetails ?? "");
        objectReq.append("location", getLocation?.completeAddress ?? getLocation?.formattedAddress ?? "");
        getLocation && objectReq.append("latitude", getLocation.latitude);
        getLocation && objectReq.append("longitude", getLocation.longitude);
        getWebsite && objectReq.append("web_url", getWebsite);
        objectReq.append("phone", getPhone ?? "");
        ((getEmail??"").length > 1) && objectReq.append("email", getEmail ?? "");
        objectReq.append("origin_country", `${(getOriginCountry?.id) ?? -1}`);
        objectReq.append("category_id", `${(getCategory?.id) ?? -1}`);
        objectReq.append("posted_by_id", `${profileInStore.idUser}`);
        getCountry && objectReq.append("country", `${getCountry.id}`);
        getRegions && objectReq.append("region", `${(getRegions.map(reg => reg.id).join(","))}`);

        // (getAttachFile.length > 0) && objectReq.append("photo", getAttachFile[0].file);
        // (getAttachFile.length > 0) && objectReq.append("cover_photo", getAttachFile[0].file);

        for (var i = 0; i < getAttachFile.length; i++) {
            // const imageData = {
            //     uri: URL.createObjectURL(getAttachFile[i].file), // Generate a temporary URI for the file
            //     name: getAttachFile[i].file.name,
            //     type: "photo",
            // }

            objectReq.append("photo[]", getAttachFile[i].file);
        }

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, objectReq, profileInStore, true)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                clearForm();

                // setResponseAllCategory(t);

                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: "New Business Added", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (e.data && e.data.statuscode && e.data.statuscode !== 200) {
                    dispatch(ShowAlert({
                        text: `${e.data.msg}`,
                        typeAlert: enumTypeOfAlert.error,
                        lastTimeecondsUpdate: (new Date()).getSeconds()
                    }));
                }
                else if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `error: ${e}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    const callApiForGetListCategory = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.business.getListCategory;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response.category as (InterfaceNTTCategoryBusiness[]);
                setResponseAllCategory(t);

                dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.translate.createdCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `error: ${e}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    const callApiForGetAllBusinessSimilar = async () => {
        dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.business.getListOfSimilarBusiness;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme, { title: getTitle, phone: getPhone } as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, false)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response.businesses as (InterfaceNTTBusiness[]);
                setResponseAllSimilarBusiness(t)
                if (t.length > 0) {
                    setStateFetchList(EnumStateFetchList.foundData);
                }
                else {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }

                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: "Fetched.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                setStateFetchList(EnumStateFetchList.errorInRequest);
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (e.data && e.data.statuscode && e.data.statuscode !== 200) {
                    dispatch(ShowAlert({
                        text: `${e.data.msg}`,
                        typeAlert: enumTypeOfAlert.error,
                        lastTimeecondsUpdate: (new Date()).getSeconds()
                    }));
                }
                else if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `error: ${e}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    useEffect(() => {
        callApiForGetListCategory();
    }, [])
    const TitleOfCard = styled("h2")(({ theme }) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        boxSizing: "border-box",
        color: Colors.theme.blue.light,
        fontFamily: confComps.font.poppins.SemiBold,
        fontSize: '1.1rem',

    }));
    return (
        <ContainerPage title={confComps.translate.addBusiness}>
            <MainCard>
                <Box sx={{
                   marginInline: { xs: 0, sm: 0, md: 1, lg: 1.5, xl: 2 },
                    // marginInlineEnd: { xs: 0, sm: 16, md: 24, lg: 32, xl: 40 },
                    boxSizing: "border-box",


                    display: 'flex',
                    flexDirection: 'row',  // You can change this to 'column' for vertical layout
                    gap: mTheme.spacing(2), // This replaces Grid's spacing prop
                    flexWrap: 'wrap',      // Optional: Wrap items in a new line if there's not enough space
                }}
                >
                    <Grid2 container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 0, sm: 6, md: 14, lg: 22, xl: 30 }}
                        rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                        columns={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}
                    >

                        <Grid2 size={2} >
                            <TextFieldSimple title={confComps.translate.title} textValue={getTitle ?? ""} onChangeValue={setTitle} />
                        </Grid2>
                        <Grid2 size={2} >
                            <TextFieldSimple
                                title={confComps.translate.phone}
                                textValue={getPhone ?? ""}
                                onChangeValue={setPhone}
                            />
                        </Grid2>
                        <Grid2 size={2} >
                            <TextFieldSimple
                                title={confComps.translate.email}
                                textValue={getEmail ?? ""}
                                onChangeValue={setEmail}
                            />
                        </Grid2>

                        <Grid2 size={2} >
                            <TextFieldSimple title={confComps.translate.website}
                                textValue={getWebsite ?? ""} onChangeValue={setWebsite}
                            />
                        </Grid2>
                        <Grid2 size={2} >
                            <SelectCategoryBusiness
                                onChangeSelectedSubCategory={setCategory}
                                defaultSelected={getCategory}
                            />
                        </Grid2>
                        <Grid2 size={2} >
                            <AutoCompleteLocation
                                onPlaceSelected={(place: InterfaceSuggestionLocation) => {
                                    setLocation(place)
                                }}
                                title="Location"
                                isSearchByStreet={true}
                            // title={confComps.translate.location}
                            // textValue={getLocation ?? ""}
                            // onChangeValue={setLocation}
                            />
                        </Grid2>
                        <Grid2 size={2} >
                            <SelectOriginCountry
                                onChangeSelectedOriginCountry={setOriginCountry}
                                defaultSelected={getOriginCountry}

                            />
                        </Grid2>
                        <Grid2 size={2} >
                            <MultiSelectorRegion
                                onChangeSelectedRegion={(selected: InterfaceNTTRegion[]) => {
                                    setRegions(selected);
                                }}
                                defaultSelected={getRegions}
                                origin_country={getOriginCountry}
                            />
                        </Grid2>
                        <Grid2 size={2} >
                            <SelectOriginCountry
                                title={confComps.translate.country}
                                onChangeSelectedOriginCountry={setCountry}
                                defaultSelected={getCountry}
                            />
                        </Grid2>

                        <Grid2 size={4}>
                            <SubTitleSection title={confComps.translate.uploadImage} />
                            <FilesDragAndDrop
                                onUpload={(files) => {
                                    setAttachFile(files.map((fileNew, indx) => {
                                        return (
                                            {
                                                file: fileNew,
                                            }
                                        )
                                    }));
                                }}
                                count={1}
                                formats={['jpg', 'png']}
                                containerStyles={{
                                    borderRadius: "4px",
                                    boxSizing: "border-box",

                                }}
                                openDialogOnClick
                            >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    border: `1px dotted ${Colors.theme.blue.blue}`,
                                    cursor: "pointer",
                                    paddingInline: "1.6rem",
                                    paddingBlock: "1.2rem",
                                    background: "#E3F9E9",
                                    borderRadius: "0.8rem",
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "0.5rem",
                                    }}>
                                        <AttachFileRounded style={{ color: Colors.theme.blue.dark }} />
                                        {confComps.translate.dragDropFiles}
                                    </div>
                                    <div style={{
                                        borderRadius: "0.4rem", background: Colors.theme.blue.light_2,
                                        paddingInline: "0.5rem",
                                        paddingBlock: "0.1rem",
                                        boxSizing: "border-box",
                                        border: `1px solid ${Colors.theme.blue.dark}`,
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        lineHeight: "1.2rem",
                                        color: Colors.theme.blue.dark,

                                    }}>
                                        {"Choose File"}
                                    </div>

                                </div>
                            </FilesDragAndDrop>
                            <div style={{ marginTop: "2rem" }}>
                                <ListFiles Files={getAttachFile}
                                    callbackRemoveFiles={(index: number) => {
                                        setAttachFile(prev => prev.filter((prev, indexP) => indexP !== index));
                                    }}
                                    callBackUpdatedAllFiles={(files) => {
                                        console.log("Files come is:", files);
                                        setAttachFile(files);
                                    }}
                                />

                            </div>

                        </Grid2>
                        <Grid2 size={4} >
                            <TextFieldMultiLine title={confComps.translate.description}
                                onChangeText={setDetails} text={getDetails ?? ""}
                            />
                        </Grid2>
                        <Grid2 size={4} >
                            <div style={{
                                width: "100%", alignItems: "center", display: 'flex', flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                                <ButtonColor text={confComps.translate.showSimilar}
                                    iconStart={<ArrowDropDownIcon />}
                                    onclick={() => {
                                        callApiForGetAllBusinessSimilar();
                                    }}
                                    color={ColorThemForButton.orangeColorAndTransparentBackground}
                                />
                                <ButtonSave text={confComps.translate.addBusiness}
                                    callbackOnClick={() => {
                                        callApiForCreateBusiness();
                                    }}
                                />

                            </div>
                        </Grid2>
                    </Grid2>



                </Box>
                <ParentWithHeightAnimation in={getStateFetchList !== EnumStateFetchList.initiate} timeout={300} style={{
                    gap: "0.5rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }} >
                    <InfiniteScrollList
                        isDataExist={(getResponseAllSimilarBusiness).length !== 0}
                        onEndReached={async () => {
                            // let oldPage = getPaginationForLastRequest;
                            // oldPage = {
                            //     ...oldPage,
                            //     page: (oldPage.page + 1)
                            // };
                            // setPaginationForLastRequest(oldPage);
                            // console.log("callback for change state response2:", oldPage);
                            // callApiForGetForeignCompanies(oldPage);
                        }}
                        onRetryClicked={() => {
                            callApiForGetAllBusinessSimilar();
                        }}
                        stateResponseList={getStateFetchList}
                        styleSpacingMUI={{
                            columns: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 },
                            rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                            columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                        }}

                    >
                        <div style={{
                            display: 'flex', width: '100%', flexDirection: "column",
                            alignItems: 'center',
                            gap: "0.9rem"
                        }}>



                            {getResponseAllSimilarBusiness.map((businessSim, index, arr) => {
                                return (

                                    <MainCard >
                                        <Grid2 container
                                            justifyContent="space-between" alignItems="flex-start"
                                            columnSpacing={{ xs: 0, sm: 6, md: 14, lg: 22, xl: 30 }}
                                            rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                                            columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                                        >
                                            <Grid2 size={2} >
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                    justifyContent: 'start',
                                                    gap: "0.5rem",
                                                    flexWrap: "wrap",
                                                }}>
                                                    <TitleOfCard>
                                                        {"title :"}
                                                    </TitleOfCard>
                                                    {businessSim.title}
                                                </div>

                                            </Grid2>
                                            <Grid2 size={2} >
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                    justifyContent: 'start',
                                                    gap: "0.5rem",
                                                    flexWrap: "wrap",
                                                }}>
                                                    <TitleOfCard>
                                                        {"category :"}
                                                    </TitleOfCard>
                                                    {getResponseAllCategory
                                                        .filter(catMain => catMain.sub_categories
                                                            .filter(cat => cat.id === businessSim.category_id).length > 0)
                                                        .map((catMain => {
                                                            return (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: 'row', alignItems: 'center', justifyContent: 'start',
                                                                        gap: "0.5rem"
                                                                    }}
                                                                >
                                                                    {<img
                                                                        src={`${catMain.image}`} // Fallback image
                                                                        alt={`${catMain.image}`}
                                                                        style={{ width: '1rem', height: 'auto' }}
                                                                    />}
                                                                    {`${catMain.name}=>
                                                                    ${catMain.sub_categories.filter(cat => cat.id === businessSim.category_id)
                                                                            .map(cat => cat.name).join(",")
                                                                        }
                                                                    `}
                                                                </div>
                                                            )
                                                        }
                                                        ))

                                                    }
                                                </div>
                                            </Grid2>
                                            <Grid2 size={2} >
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                    justifyContent: 'start',
                                                    gap: "0.5rem",
                                                    flexWrap: "wrap",
                                                }}>
                                                    <TitleOfCard>
                                                        {`Phone:`}
                                                    </TitleOfCard>
                                                    {businessSim.phone}
                                                </div>
                                            </Grid2>
                                            <Grid2 size={2} >
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                    justifyContent: 'start',
                                                    gap: "0.5rem",
                                                    flexWrap: "wrap",
                                                }}>
                                                    <TitleOfCard>
                                                        {`Email:`}
                                                    </TitleOfCard>
                                                    {businessSim.email}
                                                </div>
                                            </Grid2>
                                            <Grid2 size={2} >
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                    justifyContent: 'start',
                                                    gap: "0.5rem",
                                                    flexWrap: "wrap",
                                                }}>
                                                    <TitleOfCard>
                                                        {`Orgin Country:`}
                                                    </TitleOfCard>
                                                    {OriginCountryData.filter(country => country.id == businessSim.origin_country).map(country => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row", alignItems: 'center',
                                                                    justifyContent: 'start',
                                                                    gap: "0.5rem"
                                                                }}>
                                                                {country.emojiU && <FlagImage emojiU={country.emojiU} />}
                                                                {country.name}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </Grid2>
                                            <Grid2 size={4} >
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                    justifyContent: 'start',
                                                    gap: "0.5rem",
                                                    flexWrap: "wrap",
                                                }}>
                                                    <TitleOfCard>
                                                        {"description: "}
                                                    </TitleOfCard>
                                                    {businessSim.details}
                                                </div>
                                            </Grid2>
                                        </Grid2>
                                    </MainCard>

                                )
                            })}
                        </div>

                    </InfiniteScrollList>

                </ParentWithHeightAnimation>
            </MainCard >

        </ContainerPage >
    );
}
export default CreateBusiness;