import React from 'react';
import logo from './logo.svg';
import './App.css';
import { AlertPopup } from './components/MUIConfig/alert_popup/AlertPopup';
import { useSelector } from 'react-redux';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { SelectedProgressbarInRoot } from './store/ProgressbarInRootSlice';
import { SelectedAlert } from './store/AlertStateModeSlice';
import { RootState } from './store/store';
import { selectedTheme } from './store/themeModeSlice';
import { ConfigComps } from './config/ConfigCOMP';
import { HelperResponsive } from './helper/Helper';
import Sidebar from './components/complex/sidebar/Sidebar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRedirectToLogin from './api/PrivateRedirectToLogin';
import { enumTypeOfAlert } from './components/MUIConfig/alert_popup/interfaceAlertPopup';
import { ProgressBarInRoots } from './components/MUIConfig/progressbar_in_root/ProgressBarInRoot';
import Colors from './config/Colors';
import Links from './config/Links';
import HttpErrorPage from './screens/error/HttpErrorPage';
import Test from './screens/test';
import LoginPage from './screens/loginAndRegister/Login/LoginPage';
import CreateGuide from './screens/panel/Guide/CreateGuide/CreateGuide';
import CreateBusiness from './screens/panel/Business/CreateBusiness/CreateBusiness';
import CreateEvent from './screens/panel/Event/CreateEvent/CreateEvent';

function App() {
  const themeInStore = useSelector((state: RootState) => selectedTheme(state));
  const alertStateInStore = useSelector(SelectedAlert);
  const progressbarInstore = useSelector(SelectedProgressbarInRoot);
  console.log("safajoooon theme in store", themeInStore);
  const mainThem = createTheme({
    ...((themeInStore as any))
  });
  // const [getSearchParamsPage, setSearchParamsPage] = useSearchParams();
  var confComps = ConfigComps();
  const mTheme = useTheme();

  const AddSidebar = (element: any) => {
    var sizeDevice = HelperResponsive();
    return (
      <div style={{
        display: "flex", flexDirection: "row",
        width: "100vw",
      }}>
        <Sidebar typeOfDisplay={sizeDevice} />
        <div style={{
          display: "flex", flexGrow: 1, flexBasis: 0, flexDirection: "row", background: Colors.theme.white.PrimaryLight, maxHeight: "100vh",
        }}>
          <div style={{
            flexDirection: "column",
            display: "flex", flexGrow: "1", flexBasis: "0",
            width: "0",
            borderStartStartRadius: "min(3rem , 4vw)",
            overflow: "hidden",
            borderEndStartRadius: "min(3rem , 4vw)",
            background: "#fff",
            boxShadow: "0px 0px 4px rgba(45,50,80,0.15)",
            zIndex: 0,
            paddingInline: "24px",
            boxSizing: "border-box",
            paddingTop: "calc(1vh + 24px)",
            overflowY: "auto",
          }}>
            {element}
          </div>
        </div>
      </div >
    );

  }
  return (
    <ThemeProvider theme={mainThem}>
      <div dir={mainThem.direction} style={{ direction: mainThem.direction, position: "relative" }}>
        <AlertPopup typeOfWarning={alertStateInStore.typeAlert ? Object.values(enumTypeOfAlert)[Object.values(enumTypeOfAlert).indexOf(alertStateInStore.typeAlert as unknown as enumTypeOfAlert)] : enumTypeOfAlert.error}
          text={alertStateInStore.text} lastTimeecondsUpdate={alertStateInStore.lastTimeecondsUpdate} />
        <ProgressBarInRoots isVisible={progressbarInstore.isVisibility} value={progressbarInstore.value} lastTimeMilisUpdate={progressbarInstore.lastTimeMilisUpdate} />
        <BrowserRouter>
          <Routes>
            {/* login and register */}
            <Route path={Links.screens.loginAndRegister.login} element={<LoginPage />} />
            {/* service */}
            <Route path='/panel' element={<PrivateRedirectToLogin />}>
              <Route path={Links.screens.panel.guide.addGuide} element={AddSidebar(<CreateGuide />)} />
              <Route path={Links.screens.panel.event.addEvent} element={AddSidebar(<CreateEvent />)} />
              <Route path={Links.screens.panel.business.addBusiness} element={AddSidebar(<CreateBusiness />)} />
            </Route>
            <Route path={"/"} element={AddSidebar(<Test />)} />
            <Route path="/404" element={<HttpErrorPage code={404} />} />
            <Route path="*" element={<HttpErrorPage code={404} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
