import { useEffect, useState } from "react";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";
import { InterfaceSubSidebar } from "./InterfaceSubSidebar";
import { BoxController, ItemSubSidebar } from "./SubSidebarStyle";
import { useLocation, useNavigate } from "react-router-dom";
import { EnumSectionsSidebar } from "../../../../config/enums/EnumSectionsSidebar";
import HeaderSubSidebar from "../../../basic/subSidebar/headerSubSidebar/HeaderSubSidebar";
import ItemsSubSidebar from "../../../basic/subSidebar/itemsSubSidebar/ItemsSubSidebar";

function SubSidebar(componentsParams: InterfaceSubSidebar) {
    var confComps = ConfigComps();
    const location = useLocation();
    const navigate = useNavigate();
    const [getKeySubSidebarSelected, setKeySubSidebarSelected] = useState<string>();
    useEffect(() => {
        if (location.pathname.split("/").length > 3) {
            var strKey = `${location.pathname.split("/")[2]}/${location.pathname.split("/")[3]}`;
            console.log("strKey strKey strKey")
            console.log(strKey)
            setKeySubSidebarSelected(strKey);
        }
        console.log("componentsParams")
        console.log(componentsParams)
    }, [location.pathname]);
    return (
        <BoxController isOpened={componentsParams.isOpen}
            isWidthSmall={componentsParams.typeOfDisplay <= enumListSizeDevices.tablet}
        >

            <div style={{
                flexBasis: "1px",
                width: "1px",
                flexGrow: "0",
                height: "100%",
                background: "#AFB2CF",
                boxSizing: "border-box"
            }} />
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                flexGrow: "1",
            }}>
                <img src={`${confComps.images.logo}`}
                    style={{}} />
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "start",
                    marginTop: "48px",
                    width: "100%",
                    paddingInline: "12px",
                    boxSizing: "border-box",
                    gap: "8px",
                }}>
                    {
                        componentsParams.listOfItems.flatMap((item, index, array) => {
                            return (
                                [
                                    <HeaderSubSidebar
                                        hasChild={item.subItems.length > 0}
                                        isSelected={getKeySubSidebarSelected == item.key}
                                        title={item.title} callbackOnClick={item.subItems.length == 0 ? () => navigate(item.link) :
                                            () => {
                                                if (getKeySubSidebarSelected == item.key) {
                                                    setKeySubSidebarSelected("-1");
                                                }
                                                else {
                                                    setKeySubSidebarSelected(item.key);
                                                }
                                            }
                                        } />,
                                    item.subItems.map((subMenu, index, arr) => {
                                        return (
                                            <ItemsSubSidebar title={subMenu.title} zIndex={(arr.length + 10) - index}
                                                isSelected={ location.pathname.includes(subMenu.key)}
                                                link={subMenu.link}
                                                isShow={getKeySubSidebarSelected == item.key} />
                                        )
                                    })
                                ]
                            )
                        })
                    }


                </div>

            </div>



        </BoxController>
    )
}
export default SubSidebar;