import { styled, useTheme } from "@mui/material/styles";
import Colors from "../../../../config/Colors";


export const BoxController = styled("div", { shouldForwardProp: (prop) => prop !== 'isOpened' && prop !== 'isWidthSmall' })<{ isOpened: boolean; isWidthSmall: boolean }>(({
    theme,
    isOpened,
    isWidthSmall,
}) => {
    return (
        {

            // position: "absolute",
            // width: isOpened ? undefined : "0",

            height: "100%",
            boxSizing: "border-box",
            display: 'flex',
            flexDirection: 'row',
            alignItems: "start",
            justifyContent: "start",
            paddingBlock: "28px",
            position: (isWidthSmall || true) ? "absolute" : undefined,
            left: isOpened ? "88px" : 0,
            top: 0,
            width: (isOpened || isWidthSmall) ? "182px" : "0",
            background: "inherit",
            transition: "width,transform 1s ease",
            transform: isOpened ? "translateX(0%)" : "translateX(-100%)",
            overflow: "hidden",
            zIndex: (isWidthSmall) ? 0 : -1000,
        }
    )
});
export const ItemSubSidebar = styled("button", {
    shouldForwardProp: (prop) => prop !== 'isSelected'
        && prop !== 'HasChild'
})<{ isSelected: boolean; HasChild: boolean }>(({
    theme,
    isSelected,
    HasChild,
}) => {
    return (
        {
            fontFamily: isSelected ? "Poppins-Medium" : undefined,
            cursor: "pointer",
            paddingBlock: "0.3rem",
            background: isSelected ? Colors.theme.blue.dark : undefined,
            color: isSelected ? "#F1F2F4" : Colors.theme.blue.dark,
            borderRadius: "12px",
            width: "100%",
            textAlign: "start",
            paddingInlineStart: "16px",
        }
    )
});