import { baseUrl } from "../api/UrlsList";
import Colors from "../config/Colors";
import configResponsive, { enumListSizeDevices } from "../config/responsive/configResponsive";

export const HelperResponsive = () => {
    if (window.innerWidth < configResponsive.display_int.small_mobile) {
        return (enumListSizeDevices.small_mobile);
    } else if (window.innerWidth < configResponsive.display_int.mobile) {
        return (enumListSizeDevices.mobile);
    } else if (window.innerWidth < configResponsive.display_int.tablet) {
        return (enumListSizeDevices.tablet);
    } else if (window.innerWidth < configResponsive.display_int.desktop) {
        return (enumListSizeDevices.desktop);
    } else {
        return (enumListSizeDevices.tv);
    }
}
export const formatDateGregorian = (posixTime: number): string => {
    const date = new Date(posixTime * 1000); // Convert POSIX time to milliseconds

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
}
export const getColorCodeOfBalanceNumber = (input: string): string => {
    const parsedNumber = parseFloat(input);

    if (isNaN(parsedNumber)) {
        // throw new Error("Input is not a valid number");
        return Colors.theme.blue.dark;
    }

    if (parsedNumber > 0) {
        return Colors.theme.green.dark; // Green
    } else if (parsedNumber < 0) {
        return Colors.theme.red.red; // red
    } else {
        return Colors.theme.blue.dark; // Dark Blue
    }
}
export const getTypeOfFileFromLink = (input: string): string => {
    const lastIndex = input.lastIndexOf(".");
    if (lastIndex !== -1) {

        return input.slice(lastIndex);
    } else {
        return ""; // if no dot is found
    }
}
export const getFileNameFromURL = (input: string): string => {
    const lastIndex = input.lastIndexOf("/");
    if (lastIndex !== -1) {

        return input.slice(lastIndex + 1);
    } else {
        return ""; // if no dot is found
    }
}
export const DownloadFile = async (fileUrl1: string, fileName: string) => {
    let fileUrl = `${baseUrl}${fileUrl1}`
    try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('There was an error downloading the file:', error);
    }
};