// store/reducers/themeSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { Direction } from "@mui/material";

export type InitialState = {
    direction: (Direction | undefined);
};
const initialState: InitialState = {
    direction: "ltr",
};
export const themeModeSlice = createSlice({
    name: "theme",
    initialState: initialState,
    reducers: {
        SetDirectionOfTheme: (state, action: { payload: Direction | undefined }) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            // state.direction = state.direction == "rtl" ? "ltr" : "rtl";
            // console.log("SetDirectionOfTheme");
            // console.log(state);
            // console.log(action);
            state.direction = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { SetDirectionOfTheme } = themeModeSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectedTheme = (state: { theme: InitialState }) => {
    // console.log("selectTheme called");
    // console.log(state.theme);
    return state.theme
};

export default themeModeSlice.reducer;