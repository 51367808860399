import { Button } from "@mui/material";
import Colors from "../../../../config/Colors";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { InterfaceButtonSave } from "./InterfaceButtonSave";
import { SaveRounded } from "@mui/icons-material";

function ButtonSave(paramsComponent: InterfaceButtonSave) {
    var confComps = ConfigComps();
    return (
        <button
            style={{
                color: Colors.theme.white.PrimaryLight,
                fontFamily: confComps.font.poppins.Medium,
                fontWeight: "200",
                fontSize: "1.12rem",
                cursor: "pointer",
                background: Colors.theme.green.dark,
                boxShadow: Colors.shadow.shadowButtonBoxHovered,
                paddingInline: "1.52rem",
                paddingBlock: "0.32rem",
                borderRadius: "100.32rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
                ...paramsComponent.style,
            }}
            onClick={paramsComponent.callbackOnClick}
        >
            {!(paramsComponent.iconStart) && <SaveRounded />}
            {(paramsComponent.iconStart) && paramsComponent.iconStart}
            {paramsComponent.text}
        </button>
    )
}
export default ButtonSave;
