import { InterfaceContainerPage } from "./InterfaceContainerPage";

function ContainerPage(componentsParams: InterfaceContainerPage) {

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: '1',
            flexBasis: '0',
            alignItems: "start",
            justifyContent: "start",
            boxSizing: "border-box",
            gap: "24px",
            paddingInline: "1rem",
            paddingBottom: "2.3rem",
            // background: "red",
        }}>
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                boxSizing: "border-box",
            }}>
                <h1>{componentsParams.title}</h1>
            </div>
            {componentsParams.children}
        </div>
    );
}
export default ContainerPage;