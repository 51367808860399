import { InterfaceIconSVG } from "../../icons/InterfaceIconSVG";

export const ErrorResponseImage: React.FC<InterfaceIconSVG> = ({ style }) => {
  return (

    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3710 3710"
      style={{ ...style }}>
      <defs>
        <style>
          {`.cls-1 {
        fill: url(#linear-gradient);
      }

      .cls-2, .cls-3, .cls-4, .cls-5 {
        fill: #fff;
      }

      .cls-6 {
        fill: #91b3fa;
      }

      .cls-7 {
        fill: #7d97f4;
      }

      .cls-8 {
        fill: #bdd0fb;
      }

      .cls-3 {
        opacity: .7;
      }

      .cls-3, .cls-4, .cls-5 {
        isolation: isolate;
      }

      .cls-4 {
        opacity: .6;
      }

      .cls-5 {
        opacity: .5;
      }

      .cls-9 {
        fill: url(#linear-gradient-8);
      }

      .cls-10 {
        fill: url(#linear-gradient-9);
      }

      .cls-11 {
        fill: url(#linear-gradient-3);
      }

      .cls-12 {
        fill: url(#linear-gradient-4);
      }

      .cls-13 {
        fill: url(#linear-gradient-2);
      }

      .cls-14 {
        fill: url(#linear-gradient-6);
      }

      .cls-15 {
        fill: url(#linear-gradient-7);
      }

      .cls-16 {
        fill: url(#linear-gradient-5);
      }

      .cls-17 {
        fill: url(#linear-gradient-59);
      }

      .cls-18 {
        fill: url(#linear-gradient-57);
      }

      .cls-19 {
        fill: url(#linear-gradient-58);
      }

      .cls-20 {
        fill: url(#linear-gradient-55);
      }

      .cls-21 {
        fill: url(#linear-gradient-56);
      }

      .cls-22 {
        fill: url(#linear-gradient-53);
      }

      .cls-23 {
        fill: url(#linear-gradient-54);
      }

      .cls-24 {
        fill: url(#linear-gradient-51);
      }

      .cls-25 {
        fill: url(#linear-gradient-52);
      }

      .cls-26 {
        fill: url(#linear-gradient-50);
      }

      .cls-27 {
        fill: url(#linear-gradient-60);
      }

      .cls-28 {
        fill: url(#linear-gradient-61);
      }

      .cls-29 {
        fill: url(#linear-gradient-41);
      }

      .cls-30 {
        fill: url(#linear-gradient-40);
      }

      .cls-31 {
        fill: url(#linear-gradient-43);
      }

      .cls-32 {
        fill: url(#linear-gradient-42);
      }

      .cls-33 {
        fill: url(#linear-gradient-45);
      }

      .cls-34 {
        fill: url(#linear-gradient-46);
      }

      .cls-35 {
        fill: url(#linear-gradient-44);
      }

      .cls-36 {
        fill: url(#linear-gradient-49);
      }

      .cls-37 {
        fill: url(#linear-gradient-47);
      }

      .cls-38 {
        fill: url(#linear-gradient-48);
      }

      .cls-39 {
        fill: url(#linear-gradient-14);
      }

      .cls-40 {
        fill: url(#linear-gradient-18);
      }

      .cls-41 {
        fill: url(#linear-gradient-22);
      }

      .cls-42 {
        fill: url(#linear-gradient-20);
      }

      .cls-43 {
        fill: url(#linear-gradient-21);
      }

      .cls-44 {
        fill: url(#linear-gradient-23);
      }

      .cls-45 {
        fill: url(#linear-gradient-15);
      }

      .cls-46 {
        fill: url(#linear-gradient-19);
      }

      .cls-47 {
        fill: url(#linear-gradient-16);
      }

      .cls-48 {
        fill: url(#linear-gradient-17);
      }

      .cls-49 {
        fill: url(#linear-gradient-13);
      }

      .cls-50 {
        fill: url(#linear-gradient-12);
      }

      .cls-51 {
        fill: url(#linear-gradient-10);
      }

      .cls-52 {
        fill: url(#linear-gradient-11);
      }

      .cls-53 {
        fill: url(#linear-gradient-27);
      }

      .cls-54 {
        fill: url(#linear-gradient-24);
      }

      .cls-55 {
        fill: url(#linear-gradient-26);
      }

      .cls-56 {
        fill: url(#linear-gradient-38);
      }

      .cls-57 {
        fill: url(#linear-gradient-35);
      }

      .cls-58 {
        fill: url(#linear-gradient-37);
      }

      .cls-59 {
        fill: url(#linear-gradient-34);
      }

      .cls-60 {
        fill: url(#linear-gradient-39);
      }

      .cls-61 {
        fill: url(#linear-gradient-36);
      }

      .cls-62 {
        fill: url(#linear-gradient-25);
      }

      .cls-63 {
        fill: url(#linear-gradient-28);
      }

      .cls-64 {
        fill: url(#linear-gradient-29);
      }

      .cls-65 {
        fill: url(#linear-gradient-33);
      }

      .cls-66 {
        fill: url(#linear-gradient-30);
      }

      .cls-67 {
        fill: url(#linear-gradient-32);
      }

      .cls-68 {
        fill: url(#linear-gradient-31);
      }`}
        </style>
        <linearGradient id="linear-gradient" x1="3611.3" y1="-355.3" x2="1104.8" y2="2757.6" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#dae3fe" />
          <stop offset="1" stop-color="#e9effd" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="2065.5" y1="1302.8" x2="2555.6" y2="2351.1" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-3" x1="1853" y1="3195.5" x2="1853" y2="2086" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-4" x1="2162.1" y1="-517.1" x2="1991.9" y2="313.4" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-5" x1="1376.4" y1="4155.5" x2="660.7" y2="2371.8" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-6" x1="971.7" y1="4317.9" x2="255.9" y2="2534.3" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-7" x1="857.4" y1="4363.8" x2="141.7" y2="2580.1" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-8" x1="909.8" y1="4342.8" x2="194.1" y2="2559.1" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-9" x1="1261.3" y1="4201.7" x2="545.6" y2="2418.1" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-10" x1="871.1" y1="4358.3" x2="155.4" y2="2574.6" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-11" x1="870.3" y1="4358.6" x2="154.5" y2="2575" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-12" x1="1053.7" y1="4285" x2="338" y2="2501.4" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-13" x1="1151.9" y1="4245.6" x2="436.2" y2="2462" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-14" x1="908" y1="4343.5" x2="192.2" y2="2559.8" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-15" x1="1480.7" y1="4113.7" x2="765" y2="2330" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-16" x1="1348.9" y1="4166.6" x2="633.2" y2="2382.9" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-17" x1="1853.6" y1="3941.7" x2="1137.9" y2="2158.1" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-18" x1="1927.7" y1="3912" x2="1212" y2="2128.3" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-19" x1="1931.4" y1="3910.5" x2="1215.6" y2="2126.9" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-20" x1="1864.5" y1="3937.3" x2="1148.8" y2="2153.7" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-21" x1="1712" y1="3998.5" x2="996.2" y2="2214.9" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-22" x1="1797.5" y1="3964.2" x2="1081.8" y2="2180.6" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-23" x1="1909.9" y1="3919.1" x2="1194.2" y2="2135.5" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-24" x1="1502.4" y1="4082.6" x2="786.7" y2="2299" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-25" x1="1611.9" y1="4038.7" x2="896.2" y2="2255.1" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-26" x1="1899.2" y1="3923.5" x2="1183.4" y2="2139.8" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-27" x1="1246.8" y1="4207.6" x2="531" y2="2423.9" gradientTransform="translate(2622.1 4509.8) rotate(-16.8) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-28" x1="1420" y1="-304.1" x2="1532.3" y2="652.3" gradientTransform="translate(534.5 3556) rotate(11.2) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-29" x1="2637.2" y1="2569" x2="2815.5" y2="2569" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-30" x1="2709.1" y1="2605.7" x2="2961.7" y2="2605.7" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-31" x1="1077.3" y1="-263.9" x2="1189.6" y2="692.6" gradientTransform="translate(534.5 3556) rotate(11.2) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-32" x1="2537.8" y1="2704.1" x2="2734.5" y2="2704.1" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-33" x1="2395.4" y1="2723.2" x2="2777.9" y2="2723.2" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4042e2" />
          <stop offset="1" stop-color="#4f52ff" />
        </linearGradient>
        <linearGradient id="linear-gradient-34" x1="1054.1" y1="3575.4" x2="798.4" y2="2580.5" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#fe7062" />
          <stop offset="1" stop-color="#ff928e" />
        </linearGradient>
        <linearGradient id="linear-gradient-35" x1="1781.6" y1="4049.2" x2="1382.6" y2="3566.9" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#e1473d" />
          <stop offset="1" stop-color="#e9605a" />
        </linearGradient>
        <linearGradient id="linear-gradient-36" x1="1872.1" y1="3974.3" x2="1473.1" y2="3492" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#e1473d" />
          <stop offset="1" stop-color="#e9605a" />
        </linearGradient>
        <linearGradient id="linear-gradient-37" x1="1721.9" y1="2995" x2="1969.4" y2="1594.8" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-38" x1="2374" y1="2810.5" x2="1093.6" y2="-155" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#fe7062" />
          <stop offset="1" stop-color="#ff928e" />
        </linearGradient>
        <linearGradient id="linear-gradient-39" x1="1607.6" y1="2032.6" x2="2261.5" y2="3622.6" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-40" x1="1724" y1="1984.7" x2="2377.9" y2="3574.7" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-41" x1="1842" y1="1936.2" x2="2495.9" y2="3526.2" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-42" x1="1961.7" y1="1887" x2="2615.6" y2="3477" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-43" x1="2094.2" y1="1832.5" x2="2748.1" y2="3422.5" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-44" x1="3667" y1="652.8" x2="2967.4" y2="-108.2" gradientTransform="translate(-2400 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-45" x1="3439.9" y1="596.2" x2="3502" y2="596.2" gradientTransform="translate(-2400 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#febbba" />
          <stop offset="1" stop-color="#ff928e" />
        </linearGradient>
        <linearGradient id="linear-gradient-46" x1="-240" y1="-3189.2" x2="429.9" y2="-2404.1" gradientTransform="translate(-2400 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#020039" />
          <stop offset="1" stop-color="#090056" />
        </linearGradient>
        <linearGradient id="linear-gradient-47" x1="3510" y1="797" x2="2810.5" y2="36.1" gradientTransform="translate(-2400 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-48" x1="3216" y1="599.4" x2="3269.6" y2="599.4" gradientTransform="translate(-2400 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#febbba" />
          <stop offset="1" stop-color="#ff928e" />
        </linearGradient>
        <linearGradient id="linear-gradient-49" x1="-333.9" y1="-3109.1" x2="336" y2="-2324" gradientTransform="translate(-2400 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#020039" />
          <stop offset="1" stop-color="#090056" />
        </linearGradient>
        <linearGradient id="linear-gradient-50" x1="1262.5" y1="2249.3" x2="1020.9" y2="1568.6" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4f52ff" />
          <stop offset="1" stop-color="#4042e2" />
        </linearGradient>
        <linearGradient id="linear-gradient-51" x1="-7093.6" y1="1079.6" x2="-7790.5" y2="188" gradientTransform="translate(4286 -5143.7) rotate(-71.7) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#febbba" />
          <stop offset="1" stop-color="#ff928e" />
        </linearGradient>
        <linearGradient id="linear-gradient-52" x1="-6998.7" y1="1005.5" x2="-7695.7" y2="113.8" gradientTransform="translate(4286 -5143.7) rotate(-71.7) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#febbba" />
          <stop offset="1" stop-color="#ff928e" />
        </linearGradient>
        <linearGradient id="linear-gradient-53" x1="1055.8" y1="2322.7" x2="814.1" y2="1642" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4f52ff" />
          <stop offset="1" stop-color="#4042e2" />
        </linearGradient>
        <linearGradient id="linear-gradient-54" x1="774.1" y1="2422.7" x2="532.4" y2="1742" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4f52ff" />
          <stop offset="1" stop-color="#4042e2" />
        </linearGradient>
        <linearGradient id="linear-gradient-55" x1="-5534.4" y1="1067.6" x2="-5654.2" y2="549.6" gradientTransform="translate(6078.4 3849.1) rotate(15) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#febbba" />
          <stop offset="1" stop-color="#ff928e" />
        </linearGradient>
        <linearGradient id="linear-gradient-56" x1="1247.2" y1="2198.3" x2="809.2" y2="1985.5" gradientTransform="translate(2567.7 2987.4) rotate(160.9)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#febbba" />
          <stop offset="1" stop-color="#ff928e" />
        </linearGradient>
        <linearGradient id="linear-gradient-57" x1="-4233.7" y1="8499.4" x2="-3992.3" y2="8499.4" gradientTransform="translate(8986.8 6428.2) rotate(-31.7) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#09005d" />
          <stop offset="1" stop-color="#1a0f91" />
        </linearGradient>
        <linearGradient id="linear-gradient-58" x1="1270.9" y1="2149.7" x2="832.9" y2="1936.8" gradientTransform="translate(2567.7 2987.4) rotate(160.9)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#febbba" />
          <stop offset="1" stop-color="#ff928e" />
        </linearGradient>
        <linearGradient id="linear-gradient-59" x1="1597.7" y1="1408.6" x2="1985" y2="1140" gradientTransform="translate(2530.7 2690.8) rotate(-179.4)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#febbba" />
          <stop offset="1" stop-color="#ff928e" />
        </linearGradient>
        <linearGradient id="linear-gradient-60" x1="1605.6" y1="1416.4" x2="2008.5" y2="1137" gradientTransform="translate(2530.7 2690.8) rotate(-179.4)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#febbba" />
          <stop offset="1" stop-color="#ff928e" />
        </linearGradient>
        <linearGradient id="linear-gradient-61" x1="679.2" y1="2456.4" x2="437.5" y2="1775.7" gradientTransform="translate(0 3710) scale(1 -1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#4f52ff" />
          <stop offset="1" stop-color="#4042e2" />
        </linearGradient>
      </defs>
      <g id="Background">
        <rect className="cls-2" width="3710" height="3710" />
      </g>
      <g id="Illustration">
        <g>
          <path className="cls-1" d="m2929.5,2832.3c-58,60.3-112.9,99.2-156.2,129.9-509.6,361.2-1112.7,255.9-1240.9,230.2-189.3-38-555.1-111.4-819.6-418.6-400.1-464.9-337.8-1197.3-27.5-1680.7,56.8-88.4,356.7-579.3,966.2-658,368.9-47.6,798.9,48.4,1110.3,306.2,653.6,541,607.1,1633.9,167.6,2091Z" />
          <g>
            <g>
              <path className="cls-8" d="m1596.8,3005.9c-17.8-321.3-137-614.3-559.9-822.8,0,0-65.7,788.1,559.9,822.8Z" />
              <path className="cls-5" d="m1480.7,2866.5c-.9,0-1.7-.3-2.4-1-239.2-233.5-388.7-576.1-390.2-579.5-.7-1.7,0-3.7,1.8-4.5,1.7-.7,3.7,0,4.5,1.8,1.5,3.4,150.4,344.8,388.7,577.3,1.3,1.3,1.4,3.5,0,4.8-.7.7-1.6,1-2.4,1Z" />
            </g>
            <g>
              <path className="cls-8" d="m2334.2,2985.9c288.9,26.7,566-40.8,808-390.4,0,0-694.2-163-808,390.4Z" />
              <path className="cls-5" d="m2498.1,2891.5c-.8,0-1.6-.3-2.2-.8-1.4-1.2-1.6-3.4-.4-4.8,86.4-101.8,227.8-166.5,331.2-202.8,111.9-39.3,204.7-54.9,205.6-55,1.9-.3,3.6.9,3.9,2.8.3,1.9-.9,3.6-2.8,3.9-3.7.6-369.2,62.8-532.7,255.5-.7.8-1.6,1.2-2.6,1.2Z" />
            </g>
            <polygon className="cls-13" points="1630.3 2377 1569.6 2921.8 2274.3 2921.8 2182.5 2377 1630.3 2377" />
            <g>
              <path className="cls-11" d="m446.5,1034.9v1247.6c0,102.6,81.8,186.6,184.4,189.2,455.7,11.8,2049.5,40.2,2483.3,33.8,74.6-1.1,135-60.8,137.1-135.3,8.4-289.8,21.9-986.8-28.3-1310.4-10.6-68.1-68.1-119.1-137-121.3-381.8-12.2-2071.4-51-2504.9-41.3-74.8,1.7-134.5,62.8-134.5,137.6Z" />
              <path className="cls-6" d="m524.8,1028.8v1185.3c0,59.9,48.1,108.8,108,109.6l2419,29.9c59.4.9,108.1-46.9,108.3-106.3l4-1174.7c.2-42.5-33.7-77.3-76.2-78.3l-2476.6-50c-47.5-1.2-86.6,37-86.6,84.5Z" />
              <path className="cls-4" d="m3178.3,2402.3h0l-2653.5-45.2c-1.9,0-3.4-1.6-3.3-3.5,0-1.9,1.7-3.4,3.5-3.3l2653.5,45.2c1.9,0,3.4,1.6,3.3,3.5,0,1.9-1.5,3.3-3.4,3.3Z" />
              <path className="cls-4" d="m1877.7,2457.5c-17.1,0-31-13.9-31-31s13.9-31,31-31,31,13.9,31,31-13.9,31-31,31Zm0-55.2c-13.3,0-24.2,10.9-24.2,24.2s10.9,24.2,24.2,24.2,24.2-10.9,24.2-24.2-10.9-24.2-24.2-24.2Z" />
            </g>
            <g>
              <path className="cls-7" d="m2291.7,3001c309.9-153.8,548.9-395.8,575.6-902.1,0,0-806.5,269.3-575.6,902.1Z" />
              <path className="cls-5" d="m2341.8,2886.7c-.4,0-.8,0-1.1-.2-1.8-.6-2.7-2.6-2.1-4.3,74-210.9,183.9-373.5,263.1-472.7,85.8-107.6,155.2-166.1,155.8-166.7,1.4-1.2,3.6-1,4.8.4,1.2,1.4,1,3.6-.4,4.8-2.7,2.3-276,234.7-416.9,636.4-.5,1.4-1.8,2.3-3.2,2.3Z" />
            </g>
            <g>
              <path className="cls-12" d="m2426.1,3033.2h-1031.4c-28,0-49.1-19.9-43.6-41.4l13.2-52.3c4-15.7,21.3-27.3,41.9-27.9l994.3-31c21.5-.7,40.5,10.7,45.2,27.1l23.9,83.4c6.2,21.7-14.9,42.2-43.4,42.2Z" />
              <path className="cls-2" d="m1949.9,2925.8c-1.9,0-3.4-1.5-3.4-3.4,0-1.9,1.5-3.4,3.4-3.5l42.6-.6h0c1.9,0,3.4,1.5,3.4,3.4,0,1.9-1.5,3.4-3.4,3.5l-42.6.6h0Z" />
              <path className="cls-2" d="m1389.2,3004.4c-.2,0-.4,0-.5,0-1.9-.3-3.1-2-2.8-3.9l6.1-38.1c3.3-20.5,20.7-35.6,41.4-35.9l451.9-6.6h0c1.9,0,3.4,1.5,3.4,3.4,0,1.9-1.5,3.4-3.4,3.5l-451.9,6.6c-17.4.3-32.1,12.9-34.8,30.1l-6.1,38.1c-.3,1.7-1.7,2.9-3.4,2.9Z" />
            </g>
          </g>
          <g>
            <g>
              <path className="cls-16" d="m2681.8,523.7c15.4.7,31,2.1,46.3,4.2,2.1.3,4.1,0,6-.5,5.2-1.6,9.3-6.1,10.1-11.8,1.1-7.8-4.5-15.1-12.3-16.1-16.1-2.2-32.5-3.6-48.7-4.4-7.9-.4-14.6,5.7-15,13.6-.4,7.9,5.7,14.6,13.6,15Z" />
              <path className="cls-14" d="m2280.1,653.7c-5.5-5.7-14.6-5.9-20.3-.4-11.7,11.2-23.1,23.1-33.8,35.3-5.2,5.9-4.6,15,1.3,20.2,3.9,3.4,9,4.3,13.6,3,2.5-.7,4.8-2.2,6.6-4.3,10.2-11.6,21-22.9,32.1-33.5,5.7-5.5,5.9-14.6.4-20.3Z" />
              <path className="cls-15" d="m2104.3,977.7c-7.8-1.1-15.1,4.4-16.1,12.3-2.2,16.1-3.7,32.5-4.5,48.7-.4,7.9,5.7,14.6,13.6,15,1.7,0,3.3-.1,4.8-.6,5.6-1.7,9.9-6.8,10.2-13,.8-15.4,2.2-30.9,4.3-46.2,1.1-7.8-4.4-15.1-12.3-16.1Z" />
              <path className="cls-9" d="m2197.7,747.6c-6.6-4.4-15.5-2.5-19.9,4.1-9,13.6-17.4,27.7-25.2,41.9-3.8,7-1.2,15.7,5.7,19.4,3.5,1.9,7.5,2.2,11,1.1,3.5-1.1,6.6-3.4,8.5-6.9,7.4-13.5,15.4-26.9,23.9-39.8,4.4-6.6,2.5-15.5-4.1-19.9Z" />
              <path className="cls-10" d="m2560.4,531.6c15.2-2.7,30.7-4.8,46-6.2,1,0,1.9-.3,2.8-.6,6.4-1.9,10.8-8.1,10.1-15-.7-7.9-7.7-13.7-15.6-13-16.1,1.5-32.4,3.7-48.4,6.6-7.8,1.4-13,8.8-11.6,16.6,1.4,7.8,8.8,13,16.6,11.6Z" />
              <path className="cls-51" d="m2138.1,857.4c-7.4-2.8-15.7,1-18.5,8.4-5.7,15.2-10.9,30.8-15.3,46.5-2.1,7.6,2.3,15.5,9.9,17.7,2.7.8,5.5.7,8,0,4.5-1.4,8.3-4.9,9.7-9.8,4.2-14.9,9.1-29.7,14.5-44.1,2.8-7.4-1-15.7-8.4-18.5Z" />
              <path className="cls-52" d="m2098.3,1102.5c-7.9.7-13.7,7.7-13,15.6,1.5,16.1,3.6,32.4,6.5,48.4,1.4,7.8,8.8,13,16.6,11.6.6-.1,1.1-.2,1.7-.4,6.9-2.1,11.2-9,10-16.2-2.7-15.2-4.8-30.7-6.2-46-.7-7.9-7.7-13.7-15.6-13Z" />
              <path className="cls-50" d="m2333.4,627.8c1.5-.4,2.9-1.1,4.2-2.1,12.5-9,25.5-17.6,38.8-25.5,6.8-4.1,9-12.9,4.9-19.7-4.1-6.8-12.9-9-19.7-4.9-13.9,8.3-27.7,17.4-40.8,26.9-6.4,4.6-7.9,13.6-3.2,20,3.7,5.1,10.1,7.1,15.8,5.3Z" />
              <path className="cls-49" d="m2442.2,566.7c.5-.1,1-.3,1.4-.5,14.2-6,28.8-11.4,43.5-16.2,7.5-2.4,11.7-10.5,9.2-18.1-2.4-7.4-10.3-11.5-17.8-9.3,0,0-.2,0-.3,0-15.5,5-30.9,10.8-45.8,17.1-7.3,3.1-10.7,11.5-7.6,18.8,2.9,6.8,10.4,10.2,17.3,8.2Z" />
              <path className="cls-39" d="m2138.2,1234.5c-2.4-7.4-10.4-11.8-17.8-9.5,0,0-.2,0-.3,0-7.6,2.3-11.6,10.7-9.2,18.2,5,15.5,10.8,31.4,17.1,46.3,2.9,6.8,10.4,10.2,17.3,8.1.5-.1.9-.5,1.4-.7,7.3-3.1,10.8-11.4,7.7-18.7-6-14.2-11.4-29-16.2-43.7Z" />
              <path className="cls-45" d="m2841.8,540.5c2.4-7.5-1.7-15.6-9.2-18.1-7.4-2.4-15-4.7-22.5-6.8-7.6-2.2-15.5,2.3-17.7,9.9-2.1,7.6,2.3,15.5,9.9,17.7,7.2,2,14.4,4.2,21.4,6.5,2.9.9,5.9.9,8.6,0,4.3-1.3,8-4.7,9.5-9.3Z" />
            </g>
            <path className="cls-47" d="m2602.6,1608.7c-15.1-1.5-30.3-3.6-45.2-6.4-7.8-1.4-15.3,3.7-16.7,11.5-1.4,7.8,3.7,15.3,11.5,16.7,15.7,2.9,31.7,5.2,47.6,6.7,1.9.2,3.8,0,5.5-.5,5.4-1.6,9.5-6.4,10.1-12.3.8-7.9-5-14.9-12.9-15.7Z" />
            <g>
              <path className="cls-48" d="m3194.1,854.3c3.1,6.4,10.4,9.5,17,7.5.7-.2,1.4-.5,2.1-.8,7.1-3.5,10.1-12,6.6-19.2-3.4-7-7-14.1-10.7-20.9-3.7-7-12.4-9.6-19.4-5.8-7,3.7-9.6,12.4-5.8,19.4,3.5,6.5,6.9,13.2,10.2,19.9Z" />
              <path className="cls-40" d="m3259.3,1024.7c-7.9.8-13.6,7.8-12.9,15.7,1.5,15.1,2.3,30.5,2.5,45.6,0,7.9,6.6,14.3,14.5,14.2,1.4,0,2.7-.2,4-.6,6-1.8,10.3-7.4,10.2-13.9-.2-16-1.1-32.1-2.6-48-.8-7.9-7.8-13.7-15.7-12.9Z" />
              <path className="cls-46" d="m3260.7,1147.7c-7.8-1-15,4.6-16,12.4-1.9,15-4.5,30.2-7.6,45.1-1.6,7.7,3.3,15.4,11,17,2.4.5,4.9.4,7.1-.3,4.8-1.5,8.8-5.4,9.9-10.7,3.3-15.6,6-31.6,8-47.4,1-7.9-4.6-15-12.4-16Z" />
              <path className="cls-42" d="m3163.9,1384.1c-8.1,12.8-16.9,25.4-26.1,37.5-4.8,6.3-3.6,15.3,2.7,20.1,3.8,2.9,8.6,3.6,12.8,2.3,2.8-.8,5.4-2.5,7.3-5.1,9.7-12.7,18.9-26,27.5-39.5,4.2-6.7,2.3-15.5-4.4-19.8-6.7-4.3-15.5-2.3-19.8,4.4Z" />
              <path className="cls-43" d="m2995.6,1552.2c-12.8,8.1-26.2,15.8-39.6,22.8-7,3.7-9.8,12.3-6.1,19.3,3.2,6.2,10.4,9.1,16.8,7.1.8-.3,1.7-.6,2.5-1,14.2-7.4,28.2-15.4,41.7-24,6.7-4.2,8.7-13.1,4.5-19.8-4.2-6.7-13.1-8.7-19.8-4.5Z" />
              <path className="cls-41" d="m3089,1477.5c-10.8,10.7-22.1,21.1-33.6,30.9-6.1,5.1-6.8,14.2-1.7,20.2,3.8,4.5,9.8,6.1,15.1,4.5,1.8-.5,3.6-1.5,5.1-2.8,12.2-10.3,24.1-21.2,35.4-32.5,5.6-5.6,5.6-14.7,0-20.3-5.6-5.6-14.7-5.6-20.3,0Z" />
              <path className="cls-44" d="m3235.1,1267.9c-7.5-2.7-15.7,1.2-18.4,8.6-5.1,14.3-11,28.5-17.3,42.3-3.3,7.2-.1,15.7,7.1,19,3.3,1.5,6.9,1.7,10.1.7,3.8-1.1,7.1-3.9,8.9-7.7,6.7-14.5,12.8-29.5,18.2-44.5,2.7-7.5-1.2-15.7-8.6-18.4Z" />
              <path className="cls-54" d="m2771.4,1632.2c-15.1,1.9-30.4,3.1-45.6,3.7-7.9.3-14.1,7-13.8,14.9.3,7.9,7,14.1,14.9,13.8,15.9-.6,32.1-2,48-3.9.8-.1,1.6-.3,2.4-.5,6.6-2,10.9-8.5,10.1-15.5-1-7.9-8.1-13.4-16-12.5Z" />
              <path className="cls-62" d="m2887.9,1604.5c-14.2,5.1-28.8,9.6-43.7,13.5-7.6,2-12.2,9.9-10.2,17.5s9.9,12.2,17.5,10.2c6.4-1.7,12.8-3.5,19.1-5.4,9-2.7,18-5.7,26.8-8.8,7.5-2.7,11.4-10.9,8.7-18.3-2.7-7.5-10.8-11.3-18.3-8.7Z" />
              <path className="cls-55" d="m3249.1,914.2c-2.5-7.5-10.5-11.8-18.1-9.2-7.5,2.4-11.6,10.5-9.2,18.1,1.2,3.8,2.5,7.7,3.6,11.5,3.2,10.7,6.2,21.6,8.7,32.5,1.8,7.7,9.5,12.5,17.2,10.7.3,0,.6-.1.9-.2,7.2-2.2,11.6-9.6,9.8-17-2.7-11.4-5.8-22.9-9.2-34.2-1.2-4.1-2.5-8.1-3.8-12.1Z" />
            </g>
            <path className="cls-53" d="m2485.1,1583.7c-7-2.3-14.1-4.8-21-7.5-7.4-2.8-15.7.9-18.5,8.4-2.8,7.4.9,15.7,8.4,18.5,7.3,2.8,14.8,5.4,22.2,7.9,2.9,1,5.9.9,8.6.1,4.3-1.3,8-4.6,9.5-9.2,2.5-7.5-1.6-15.6-9.1-18.1Z" />
            <g>
              <path className="cls-63" d="m3125.5,948.1l-80.7-11.4c-15.4-34.4-36.1-65.5-60.6-92.7l-104.2,20-79.6,86.3,70.3,152.2-177.9-5.9-104,77.4,14.1,136.6-109.3-21.2-37.2,36.9-21,78.5c-.7,2.8.4,5.7,2.7,7.3l81.1,54.7c2.4,1.6,5.5,1.5,7.8-.2l65.2-49.1c35.6,13.5,73.6,21.5,112.8,23l40.8,70.6c1.4,2.5,4.3,3.8,7.1,3.2l96-18.7c2.8-.5,5-2.8,5.4-5.6l11.4-80.7c35.8-16.1,68-37.7,96-63.6l78.8,21.1c2.8.7,5.7-.4,7.3-2.7l54.7-81.1c1.6-2.4,1.5-5.5-.2-7.8l-49.1-65.2c13.5-35.6,21.5-73.6,23-112.8l70.6-40.8c2.5-1.4,3.8-4.3,3.2-7.1l-18.7-96c-.5-2.8-2.8-5-5.6-5.4Z" />
              <path className="cls-64" d="m2750.9,1151.7c-34.5,16.2-75.1,3.9-95.3-27.3l-18.4,13.7c23.9,35.8,68.8,52,110,39.5,4.5-1.4,9.1-3.1,13.5-5.2,29.9-14,49.3-41.2,54.9-71.5l-23.3-.8c-5,21.8-19.5,41.3-41.4,51.5Z" />
              <path className="cls-66" d="m2951.2,1011.3c-15.4-51-46.9-95-90.6-126.2l-7.8,8.5c42.2,29.8,72.7,72.1,87.5,121.1,2.3,7.5,4.2,15.2,5.7,23,8.7,44.6,3.8,88.7-11.7,128.1-27.9,70.8-90.3,126.4-170.4,142-16.1,3.1-32.6,4.6-48.9,4.2-3.2,0-5.8,2.4-5.9,5.6,0,.6,0,1.2.2,1.8.7,2.3,2.8,4,5.4,4.1,1,0,1.9-.5,2.9-.5.5,0,1-.5,1.5-.5h.5c12.9,0,25.8-.5,38.6-2.6,2.6-.4,5.2-.6,7.8-1.1,8.2-1.6,16.2-3.5,24.1-5.8,53.1-16,98.7-49.5,130.2-96.1,36.1-53.5,49.2-117.9,36.9-181.3-1.6-8.2-3.6-16.2-6-24.1Z" />
            </g>
            <g>
              <path className="cls-68" d="m2519.6,1248.6l-13.4-130.2,104-77.4,173.3,5.8c2.1,0,3.5-2.1,2.7-4l-68.4-148,79.6-86.3,104.2-20c-1-1.1-1.9-2.2-2.9-3.3l21.1-78.8c.7-2.8-.4-5.7-2.7-7.3l-81.1-54.7c-2.4-1.6-5.5-1.5-7.8.2l-65.2,49.1c-35.6-13.5-73.6-21.5-112.8-23l-40.8-70.6c-1.4-2.5-4.3-3.8-7.1-3.2l-96,18.7c-2.8.5-5,2.8-5.4,5.6l-11.4,80.7c-35.8,16.1-68,37.7-96,63.6l-78.8-21.1c-2.8-.7-5.7.4-7.3,2.7l-54.7,81.1c-1.6,2.4-1.5,5.5.2,7.8l49.1,65.2c-13.5,35.6-21.5,73.6-23,112.8l-70.6,40.8c-2.5,1.4-3.8,4.3-3.2,7.1l18.7,96c.5,2.8,2.8,5,5.6,5.4l80.7,11.4c16.1,35.8,37.7,68,63.6,96v.3c0,0,37.2-36.9,37.2-36.9l103,20c3.2.6,6-2,5.7-5.2Z" />
              <path className="cls-67" d="m2572.9,1068.7c-1.8-2.8-3.5-5.7-5-8.9-17.7-37.6-1.5-82.6,36.2-100.3,3.4-1.6,6.9-2.9,10.4-4,15.4-4.6,31.8-4.2,47.2,1.3,18.9,6.8,34.1,20.6,42.7,38.9,7.4,15.8,8.8,32.9,5.2,48.8l23.3.8c3.6-19.4,1.3-40.1-7.7-59.4-11.2-23.8-30.9-41.8-55.6-50.7-24.7-8.9-51.4-7.7-75.2,3.5-49.1,23.1-70.2,81.7-47.2,130.8,2.1,4.5,4.6,8.8,7.4,12.8l18.4-13.7Z" />
              <path className="cls-65" d="m2772.4,825.6c-53.5-36.1-117.9-49.2-181.3-36.9-130.8,25.4-216.7,152.6-191.2,283.5,1.6,8.1,3.5,16,5.9,23.8,10.2,33.9,27.8,65.1,51.4,91.5,2.1,2.4,5.7,2.5,8.1.4,1.7-1.5,2.3-3.9,1.7-5.9-.2-.8-.6-1.5-1.2-2.2-22.6-25.1-39.2-54.9-49-87.1-2.2-7.4-4.1-15-5.6-22.7-24.2-124.7,57.4-245.8,182.1-270,60.4-11.7,121.7.7,172.7,35.1,1.4.9,2.8,1.9,4.1,2.9l7.8-8.5c-1.8-1.3-3.7-2.6-5.6-3.9Z" />
            </g>
          </g>
          <g>
            <g>
              <circle className="cls-2" cx="877.9" cy="820.1" r="282" transform="translate(-322.7 861) rotate(-45)" />
              <circle className="cls-59" cx="877.9" cy="820.1" r="272.6" />
              <path className="cls-3" d="m1102.7,709.7c.4,0,.9,0,1.3-.3,1.4-.7,2-2.5,1.3-3.9-43.6-86.4-130.7-140-227.4-140s-30.9,1.4-45.9,4.1c-1.6.3-2.6,1.8-2.3,3.3.3,1.6,1.8,2.6,3.4,2.3,14.7-2.7,29.8-4,44.9-4,94.5,0,179.6,52.4,222.2,136.8.5,1,1.5,1.6,2.6,1.6Z" />
              <circle className="cls-2" cx="872" cy="826" r="230.7" transform="translate(-328.7 858.5) rotate(-45)" />
            </g>
            <g>
              <path className="cls-57" d="m886.2,870.9l-26.1-.6c-7.3-.2-13.3-6.4-13.7-13.7l-9.6-184c-.2-3.8,1.2-6.9,3.8-9.5,2.6-2.6,6.2-3.4,10-3.1l45,3.1c3.7.3,7.2,1.5,9.7,4.4,2.5,2.9,3.8,6.3,3.7,10l-9.3,180.6c-.4,7.2-6.3,13-13.4,12.8Z" />
              <path className="cls-61" d="m846,915.8c6.5-6.2,15.7-9.2,27.5-9.1,12.2.2,21.4,3.4,27.7,9.6,6.3,6.2,9.4,15.2,9.4,27.1s-3.2,20.4-9.6,26.7c-6.5,6.3-15.6,9.5-27.5,9.5s-20.8-3.3-27.4-9.8c-6.6-6.6-9.9-15.5-9.9-26.9,0-11.8,3.3-20.9,9.8-27.1Z" />
            </g>
          </g>
          <g>
            <g>
              <path className="cls-58" d="m1907.4,697.2l-728,1223.2c-16,26.9,4.9,59.7,38.1,60l1443.1,11.9c33.4.3,54.9-32.5,39.2-59.6l-715.1-1235.1c-16.4-28.4-60.5-28.6-77.3-.4Z" />
              <path className="cls-56" d="m1911,770.3l-661.2,1110.9c-14.5,24.4,4.5,54.2,34.6,54.5l1310.6,10.8c30.3.2,49.8-29.5,35.6-54.1l-649.4-1121.7c-14.9-25.8-55-26-70.2-.4Z" />
            </g>
            <g>
              <path className="cls-60" d="m1671.6,1701.6l-86,2.8v-153.6l86-1.9v26.5l-54.2,1.3v33.7l50.5-1.3v26.6l-50.5,1.4v39.5l54.2-1.6v26.8Z" />
              <path className="cls-30" d="m1739,1641.1v58.4l-31.3,1v-152.4l43-1c19.9-.4,34.7,3,44.2,10.3,9.5,7.3,14.3,18.5,14.3,33.8s-2.4,16.9-7.1,24c-4.7,7.1-11.4,12.7-20.1,16.9,22,33.4,36.3,55,42.9,64.7l-34.3,1.1-35-57.2-16.6.5Zm0-26.2l10.1-.3c9.8-.3,17.1-2.2,21.8-5.7,4.7-3.5,7-8.9,7-16.2s-2.4-12.3-7.2-15.2c-4.8-2.9-12.2-4.3-22.2-4l-9.5.2v41.1h0Z" />
              <path className="cls-29" d="m1879,1637.1v57.9l-30.7,1v-151.1l42.2-.9c19.6-.4,34.1,3,43.4,10.2,9.4,7.2,14,18.4,14,33.5s-2.3,16.7-7,23.7c-4.6,7-11.2,12.6-19.7,16.7,21.6,33.2,35.7,54.5,42.2,64.2l-33.7,1.1-34.4-56.7-16.3.5Zm0-26l9.9-.3c9.7-.3,16.8-2.1,21.4-5.6,4.6-3.5,6.9-8.8,6.9-16s-2.3-12.2-7-15.1c-4.7-2.9-12-4.2-21.9-4l-9.3.2v40.7Z" />
              <path className="cls-32" d="m2114.8,1612.9c0,24.6-5.8,43.6-17.3,57.2-11.5,13.6-28.1,20.7-49.8,21.4-21.7.7-38.4-5.4-50.1-18.3-11.7-13-17.6-31.9-17.6-56.8s5.9-44.1,17.6-57.5c11.7-13.4,28.5-20.3,50.2-20.8,21.7-.5,38.3,5.7,49.7,18.6,11.4,12.9,17.1,31.6,17.1,56.3Zm-103.1,2.8c0,16.7,3,29.2,9,37.5,6,8.3,15,12.2,27,11.8,23.9-.7,35.8-17.8,35.8-51.3s-11.9-50-35.6-49.4c-11.9.3-21,4.7-27,13.4-6.1,8.6-9.1,21.3-9.1,38Z" />
              <path className="cls-31" d="m2178.3,1628.6v56.7l-29.6.9v-148.2l40.6-.9c18.9-.4,32.8,2.9,41.8,10,9,7.1,13.5,18,13.5,32.9s-2.2,16.4-6.7,23.3c-4.5,6.9-10.8,12.3-19,16.4,20.8,32.5,34.3,53.5,40.6,63l-32.4,1-33.1-55.6-15.7.4Zm0-25.5l9.5-.3c9.3-.2,16.2-2.1,20.6-5.5,4.4-3.4,6.6-8.7,6.6-15.7s-2.3-11.9-6.8-14.8c-4.5-2.9-11.5-4.2-21-3.9l-8.9.2v40h0Z" />
            </g>
          </g>
          <g>
            <path className="cls-35" d="m1045.8,3113.9s-7.2,41-10.8,81.6c-1.4,15.9,10.8,29.6,26.8,30.3,54.1,2.3,169.7,6.6,211.2,4.8,6.2-.3,10.3-6.7,8-12.5h0c-1.9-4.9-5.7-8.8-10.4-11l-191-87.8-33.7-5.5Z" />
            <path className="cls-33" d="m1039.9,3089.8l5.8,49.1s33.5,15.6,56.3-2l-13.9-54.7-48.3,7.6Z" />
            <path className="cls-34" d="m1044.2,2167.5c-2.5,12.8-13.5,639.3,53.5,920.1,1.1,4.6-1.2,9.3-5.6,11.1-10.3,4.2-30.4,10.3-54.5,11.1-7.5.3-14.2-4.6-16.3-11.8-19.1-65.1-110.9-391.5-122.8-703.3-13.4-348.4,0-11.9,0-11.9l7.8-215.4h137.9Z" />
            <path className="cls-37" d="m826,3135.3s-11.5,20.7-22.7,75.7c-2,9.9,4.9,19.4,15,20.4,17.9,1.8,45.3,4,67.4,3.9,10.1,0,18.1-8.9,16.8-19-2.4-20.1-10-53-33.5-78.7l-43-2.3Z" />
            <path className="cls-38" d="m815.9,3072.2l13.6,74.2s13.4,10,40.1,2.8l-6.5-79.8-47.2,2.9Z" />
            <path className="cls-36" d="m779.2,2186.7c-54.8,330.6,11.9,817,34.4,912.2,1.4,5.8,6.1,10.3,12,11.3,10,1.7,26.6,3,42.8-1.7,5.6-1.6,9.6-6.7,9.8-12.5,3-77-2.4-549.5,48.6-736.3,2.6-9.5,8.4-17.7,16.4-23.4l99.1-70.4,7.6-113.8-270.6,34.7Z" />
            <g>
              <g>
                <g>
                  <g>
                    <path className="cls-26" d="m1266.8,1715.5c-8.3,59.2-17.1,72.8-23.9,73.2-21.7,1.1-17.4-132.4-96.9-199.9-50.2-42.7-118.8-46.2-172.6-41.2,25.1,90.1,50.2,180.1,75.3,270.1,11,26.9,58.8,135.7,166.9,168.7,22,6.7,54.8,16.7,85.3,3.6,60.2-25.9,88.4-131.8,61.5-264.5-12.6-4.7-30.2-9.7-95.6-10Z" />
                    <path className="cls-24" d="m1247.7,1484.6l10,56.3c16,68.8,17.5,126.2,9.2,174.6,13.7.6,28.2,1.5,43.2,3,18.5,1.8,36,4.2,52.4,7-8.6-70-55.2-186.2-55.2-186.2-28.1-40.6-31.4-14.2-59.5-54.7Z" />
                  </g>
                  <path className="cls-25" d="m1361.4,1366c-11.3-.3-22.1,4.5-29.3,13.2l-71,84.1s-12.9-2.6-14.1-27.4c-1.2-24.7-27.5-27.7-27.5-27.7,0,0,3.8,34.5,11,85.6,4.1,29.2,21.4,50.5,35.4,63.4l41.1-14.1c37.7-53.9,61.1-113.2,74.1-176.6l-19.6-.5Z" />
                </g>
                <path className="cls-22" d="m692.3,1594.9c4.7-1.2,37.1-34.7,41.9-35.6,79.7-15.2,278.7-68.8,411.8,29.5.3,2.8.5,5.1.7,7,1.3,1.8,3.1,4.7,5.5,9.4,12.6,24.4,27.7,48.1,44.9,70.2-3.8,3.8-7,8.2-9.3,13.1-32,68.5-153.3,342.3-132,509.6,1.1,8.6-4.8,16.6-13.4,17.8-47.1,6.5-187.3,22.4-259.7-7.5-4.2-1.7-6.9-5.9-6.8-10.4.5-33.6-.9-179.3-54.4-355-2-6.5-3.6-13.2-4.9-19.9-.7,1.4-1.3,2.7-1.9,4l-22.4-232.1Z" />
                <path className="cls-23" d="m813.9,1544.1c-148.6,10.9-179.7,95.8-282.4,43.9l18.2,159.1c38.1,42.1,95.6,70.7,168.1,81.5l96.2-284.4Z" />
              </g>
              <g>
                <path className="cls-20" d="m915.5,1400.2c-25.5,50.8-36.1,102.5-39.3,150.6,5.5,2,78.6,52.8,127.5,3.7-10.5-33.7-8.7-84.4-6.1-136.4l-82.2-17.9Z" />
                <g>
                  <path className="cls-21" d="m1048.1,1266.8c1.4,2.3,37.9,174.6,37.9,174.6l-98.8,18.8-58.9-158.1s96.3-73.1,119.8-35.2Z" />
                  <path className="cls-18" d="m966,1316c-2.6,3.6-3.5,8.2-2.2,12.5l24.1,84.4c.4,1.3.9,2.5,1.6,3.6,3.9,6.2,17.9,25.1,36.8,9.6,16.1-13.2,31.1-25.1,44.5-28,7.8-1.7,15.5,3.4,17.2,11.2,3.4,16.2,9.5,49,10.9,78.8.3,7.5-5.2,13.9-12.7,14.9-19.6,2.4-58.1,6-90.4,2-5.3-.7-9.8-4.2-11.7-9.1-8.5-21.9-32.4-83.2-41.8-99.4-8.8-15.1-32-16.5-42.7-16.3-4.1,0-7.9-1.5-10.8-4.5-8.5-8.8-22-28.6,1-49.9,5.7-5.3,5.9-14.3.8-20.1-9.3-10.4-17.8-27.6,0-47,17.9-19.6,39.5-16,53.3-10.4,6.1,2.5,13.5.9,17.5-4.4,1.8-2.3,4.5-4,8.7-3.7,7.9.5,14.7-5.1,15.2-13,.9-13.6,7.3-29.8,30.9-35.6,43.3-10.6,67.3,32.1,68.1,62.9.8,30.8-46.9,39.7-74.3,35.9-20.2-2.8-36.6,15.7-43.9,25.6Z" />
                  <path className="cls-19" d="m957.1,1428.6s-9.2-54.5-31.2-58.5c-22-4-44.6,12.9-24.4,44.1,25.1,38.8,55.6,14.4,55.6,14.4Z" />
                </g>
              </g>
              <g>
                <path className="cls-17" d="m920.8,1232.3c3.8-.2,8,.2,12.6,1.2,10.4,2.2,24.4,7.6,20.7,17.6-5.4,15.1-33.7,1-48.7,25.3-13.2,21.4-2.4,44.7-6.5,67.8-2,11.2-9.2,27.9-19.2,34.5-4.5,2.9-39.9,8.6-41.9,2.3,0,0-9.9-32-9.9-32-8.9-28.8,25.2-76.7,42.8-96.1,13.6-15,32-19.9,50.1-20.6Z" />
                <g>
                  <path className="cls-27" d="m887.2,1347.8l-33.9,28.2c-65,27.7-112.3,60.2-146.5,95.5-12.4-23.3-32.5-50.3-64.5-71.3,65.5-32.3,114.3-45.7,114.3-45.7,132.3-53.2,130.5-6.7,130.5-6.7Z" />
                  <path className="cls-28" d="m706.8,1471.5c-106.9,110.1-75.7,54.1-76.5,148-.3,35-30.7,168.6-30.7,168.6-327-141.2-54.1-334,42.7-387.9,32,21,52.1,48,64.5,71.3Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}